import { Typography } from 'antd';
import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {  SessionStorageVariables } from '../constants';
import { useAuth } from './auth-context';
import { RoutePaths } from '../route-definitions';

interface ProtectedRouteForMemberProps {
  children: any;
}
export const ProtectedRouteForMember: FC<ProtectedRouteForMemberProps> = (props) => {
  const auth = useAuth();
  const location = useLocation();

  // if not login yet, redirect to login page
  if (!auth?.token && !sessionStorage.getItem(SessionStorageVariables.AuthToken)) {
    return <Navigate to={RoutePaths.Login.path} replace state={{ from: location }} />;
  }

  // if login, is a member (isLeader = false), render the page
  if (!auth?.isLeader && sessionStorage.getItem(SessionStorageVariables.IsLeader) === "false") {
    return props.children;
  }
  return <Typography.Text>You don't have permission to access this page</Typography.Text>;

};
