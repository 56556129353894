import { Button, Card, Checkbox, Col, Form, Grid, Input, List, Popconfirm, Row, Typography } from 'antd';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { CreateWebsiteFormFieldNames, HandleErrorFromBackend, ServerURL } from '../../constants';
import { StudentAPI } from '../../type-defs';
import { FileUpload } from './file-upload';
import { GroupBox } from './group-box';
import { RequiredAsterisk } from './required-asterisk';
import { SearchBar, SearchBarOptionType } from './search-bar';
const { useBreakpoint } = Grid;
const { Meta } = Card;
interface SearchAddStudentsProps {
  title: string;
  subtitle: string;
  onAdd: (id: number) => void;
  onRemove: (id: number) => void;
  selectedAccounts: StudentAPI[];
  allStudentList: StudentAPI[];
  formItemName: string;
  formItemErrorMessage?: string;
  isRequired?: boolean;
  updateMemberPhoto: (member: StudentAPI) => void;
  onLookingForMembersCheckChanged?: (checked: boolean) => void;
  memo?: string | null;
}

export const SearchAddStudents: FC<SearchAddStudentsProps> = (props) => {
  const [lookingForMembers, setLookingForMembers] = useState(false);

  useEffect(() => {
    if (props && props.memo) {
      setLookingForMembers(true);
    }
  }, [props]);

  const updateMemberPhoto = async (member: StudentAPI, photo: string | undefined) => {
    const updatedMemberInfo = {
      ...member,
      user: {
        ...member.user,
        photo: photo ?? null,
      },
    };
    // call api to update photo

    await axios
      .put(`${ServerURL}/api/student/${member.user.id}/`, updatedMemberInfo)
      .then((res) => {
        props.updateMemberPhoto(updatedMemberInfo);
      })
      .catch((err) => {
        HandleErrorFromBackend(err);
      });
  };

  const onLookingForMemberCheckChanged = (e: any) => {
    setLookingForMembers(e.target.checked);
    props.onLookingForMembersCheckChanged?.(e.target.checked);
  };
  return (
    <>
      <GroupBox
        title={
          <Typography.Title level={3}>
            {props.isRequired ? (
              <>
                <RequiredAsterisk /> {props.title}
              </>
            ) : (
              props.title
            )}
          </Typography.Title>
        }
        content={
          <>
            <Row style={{ alignItems: 'baseline' }} gutter={[16, 16]}>
              <Col xl={{ span: 7 }} lg={{ span: 7 }} xs={{ span: 24 }}>
                <Typography.Title level={4}>Search</Typography.Title>
                <SearchBar onSelect={props.onAdd} options={props.allStudentList as SearchBarOptionType[]} />
              </Col>
              <Col xl={{ span: 17 }} lg={{ span: 17 }} xs={{ span: 24 }}>
                <Typography.Title level={4}>{props.subtitle}</Typography.Title>
                <Form.Item
                  name={props.formItemName}
                  rules={[
                    {
                      validator: (_, value) => {
                        if (props.isRequired) {
                          if (value?.length > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(props.formItemErrorMessage));
                        }
                      },
                    },
                  ]}
                >
                  <List
                    size="small"
                    grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 2, xl: 3, xxl: 4 }}
                    dataSource={props.selectedAccounts}
                    locale={{ emptyText: 'No members added yet.' }}
                    renderItem={(member: StudentAPI) => {
                      return (
                        <List.Item key={member.user_id} style={{ padding: '5px' }}>
                          <Card
                            style={{ textAlignLast: 'center', width: 240 }}
                            cover={
                              <FileUpload
                                prompt="Upload Photo"
                                value={
                                  member.user.photo
                                    ? [
                                        {
                                          uid: member.user.photo,
                                          name: member.user.photo,
                                          status: 'done',
                                          url: member.user.photo,
                                        },
                                      ]
                                    : []
                                }
                                onChange={(value) => {
                                  updateMemberPhoto(member, value && value.length > 0 ? value[0].url : undefined);
                                }}
                                errorMessage="You can only upload JPG/JPEG/PNG file."
                                acceptedFileTypes="image/png,image/jpeg,image/jpg"
                                listType="picture-card"
                              />
                            }
                            actions={
                              !member.is_leader
                                ? [
                                    <Popconfirm title="Are you sure you want to delete?" onConfirm={() => props.onRemove(member.user_id)}>
                                      <Button type="primary" danger style={{ width: '100%', height: '100%', margin: 0 }}>
                                        Remove
                                      </Button>
                                    </Popconfirm>,
                                  ]
                                : []
                            }
                          >
                            <Meta
                              style={{ textAlign: 'center', wordWrap: 'break-word' }}
                              title={
                                <>
                                  <Typography.Text>
                                    {member.user.first_name} {member.user.last_name}
                                  </Typography.Text>
                                  {member.is_leader ? <Typography.Text type="secondary"> (Leader)</Typography.Text> : <></>}
                                </>
                              }
                              description={`(${member.user.email})`}
                            />
                          </Card>
                        </List.Item>
                      );
                    }}
                  ></List>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Checkbox checked={lookingForMembers} onChange={(e) => onLookingForMemberCheckChanged(e)}>
                  Looking for members?
                </Checkbox>
              </Col>
            </Row>
            <br />
            {lookingForMembers ? (
              <>
                <Form.Item
                  name={CreateWebsiteFormFieldNames.Memo}
                  label="Memo"
                  rules={[
                    {
                      required: lookingForMembers,
                      message: 'Please input your memo.',
                    },
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Describe the positions your team is looking for" />
                </Form.Item>
                <Form.Item
                  name={CreateWebsiteFormFieldNames.ContactPoint}
                  label="Contact Point"
                  rules={[
                    {
                      required: lookingForMembers,
                      message: 'Please input your contact point.',
                    },
                  ]}
                >
                  <Input placeholder="Contact point" />
                </Form.Item>
              </>
            ) : (
              <></>
            )}
          </>
        }
      />
    </>
  );
};
