import { EyeOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Form, Grid, Image, List, Popconfirm, Row, Typography } from 'antd';
import { FC } from 'react';
import { AvatarSize } from '../../constants';
import { UserAPI } from '../../type-defs';
import { GroupBox } from './group-box';
import { RequiredAsterisk } from './required-asterisk';
import { SearchBar, SearchBarOptionType } from './search-bar';
const { useBreakpoint } = Grid;

interface SearchAddUsersProps {
  title: string;
  subtitle: string;
  onAdd: (id: number) => void;
  onRemove: (id: number) => void;
  selectedAccounts: UserAPI[];
  items: UserAPI[];
  formItemName: string;
  formItemErrorMessage?: string;
  isRequired?: boolean;
}

// this is used to add advisor and stakeholders
export const SearchAddUsers: FC<SearchAddUsersProps> = (props) => {
  const screens = useBreakpoint();
  return (
    <>
      <GroupBox
        title={
          <Typography.Title level={3}>
            {props.isRequired ? (
              <>
                <RequiredAsterisk /> {props.title}
              </>
            ) : (
              props.title
            )}
          </Typography.Title>
        }
        content={
          <>
            <br />
            <Row style={{ alignItems: 'baseline' }} gutter={[16, 16]}>
              <Col xxl={10} xl={10} xs={24} md={14} sm={24}>
                <Typography.Title level={4}>Search</Typography.Title>
                <SearchBar onSelect={props.onAdd} options={props.items as SearchBarOptionType[]} />
              </Col>
              <Col span={24}>
                <Typography.Title level={4}>{props.subtitle}</Typography.Title>
                <Form.Item
                  name={props.formItemName}
                  label=""
                  rules={
                    props.isRequired
                      ? [
                          {
                            validator: (_, value) => {
                              if (props.isRequired) {
                                if (value?.length > 0) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error(props.formItemErrorMessage));
                              }
                            },
                          },
                        ]
                      : []
                  }
                >
                  <List
                    size="small"
                    grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 6 }}
                    dataSource={props.selectedAccounts}
                    locale={{ emptyText: 'No data.' }}
                    renderItem={(member: UserAPI) => (
                      <List.Item key={member.user.id}>
                        <Card style={{ width: 220, height: 140 }}>
                          <Card.Meta
                            avatar={<Avatar size={AvatarSize} src={<Image src={member.user.photo} preview={{ mask: <EyeOutlined /> }} />} />}
                            title={
                              <Typography.Text style={{ whiteSpace: 'break-spaces', fontSize: '0.75rem' }}>
                                {member.user.first_name + ' ' + member.user.last_name}
                              </Typography.Text>
                            }
                          />
                          <br />
                          <Typography.Text style={{ color: 'grey', fontSize: '0.75rem', whiteSpace: 'break-spaces' }}>
                            {member.user.email}
                          </Typography.Text>
                        </Card>
                        <Popconfirm title="Are you sure you want to delete?" onConfirm={() => props.onRemove(member.user.id)}>
                          <Button style={{ width: 220 }} type="primary" danger>
                            Remove
                          </Button>
                        </Popconfirm>
                      </List.Item>
                    )}
                  ></List>
                </Form.Item>
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
};
