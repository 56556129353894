import { Card, Carousel, Col, Divider, Grid, List, Row, Typography } from 'antd';
import { FC } from 'react';
import Deployment from '../images/Deployment.png';
import Design from '../images/Design.png';
import Implementation from '../images/Implementation.png';
import Inception from '../images/Inception.png';
import Planning from '../images/Planning.png';
import Testing from '../images/Testing.png';
import image4 from '../images/bloomberg.png';
import image1 from '../images/senior-project-poster-pg-2.jpg';
import image2 from '../images/SeniorProjectShowcase.png';
import image3 from '../images/slider-4.png';
import { Link } from 'react-router-dom';
import '../utils/home.css';
import { RoutePaths } from '../route-definitions';

const { Title, Paragraph, Text } = Typography;
const { Meta } = Card;
const { useBreakpoint } = Grid;

const CardListData = [
  {
    image: Inception,
    title: 'Project Inception',
    description: 'Project ideas are elicited from industry, academia, and from the students themselves.',
  },
  {
    image: Planning,
    title: 'Project Planning',
    description: 'Students develop plans after gathering requirements and/or building prototypes.',
  },
  {
    image: Design,
    title: 'Design',
    description: 'Requirements are converted into comprehensive designs at many levels of detail.',
  },
  {
    image: Implementation,
    title: 'Implementation',
    description: 'Designs are materialized into functional products via a series of iterations.',
  },
  {
    image: Testing,
    title: 'Testing',
    description: 'Formal testing is carried out using tools like unit-testing.',
  },
  {
    image: Deployment,
    title: 'Deployment',
    description: 'Final products are delivered to the stakeholders for deployment.',
  },
];

interface HomeProps { }

export const Home: FC<HomeProps> = (props) => {
  const screens = useBreakpoint();

  const prevArrow = <div className="carousel-arrow prev-arrow">&#10094;</div>;
  const nextArrow = <div className="carousel-arrow next-arrow">&#10095;</div>;

  return (
    <>
      <Typography>
        <Title level={1} style={{ textAlign: 'center', paddingBottom: '40px' }}>
          College of Computing and Informatics Senior Project
        </Title>
        <Paragraph style={{ fontSize: '18px' }}>
          <Text strong>
            The Senior Project is a multi-term capstone experience involving in-depth study and application of computing and informatics.&nbsp;
          </Text>
          Students work in teams to develop a significant product requiring use of a development process that includes planning, specification,
          design, implementation, evaluation, and documentation. Projects are often conceived by external stakeholders who guide the requirements
          process and ultimately use the resulting application. Groups may be interdisciplinary with students from varied departments within the
          College of Engineering and the Digital Media Program.
        </Paragraph>

        <Divider />

        <Carousel autoplay prevArrow={prevArrow} nextArrow={nextArrow}>
          <div>
            <div className="slide-content">
              <img src={image2} alt="Congratulations to The 2022-2023 Winners" className="slide-img" />
              <div className="slide-text">
                <h3>Congratulations to the 2022-2023 Winners!</h3>
                <p>The winners of the 2022-2023 Senior Project Showcase have been announced.</p>
                <a style={{ textAlign: 'center' }}>
                  <Link to={RoutePaths.Winners.path}>
                    View Winners
                  </Link>
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="slide-content">
              <img src={image4} alt="Bloomberg" className="slide-img" />
              <div className="slide-text">
                <h3>Bloomberg</h3>
                <p>Bloomberg to sponsor Drexel University's CCI' 2022-23 Senior Project Showcase as part of Executive New Partnership</p>
                <a href="https://drexel.edu/cci/stories/bloomberg-to-sponsor-drexel-college-of-computing-informatics-2022-2023-senior-project-showcase/">
                  More Info
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="slide-content">
              <img src={image3} alt="Have an Idea for Next Year?" className="slide-img" />
              <div className="slide-text">
                <h3>Have an Idea for Next Year?</h3>
                <p>We are always looking for new project ideas, get a head start and be on the list of potential projects for 2023-2024</p>
                <a style={{ textAlign: 'center' }}>
                  <Link to={RoutePaths.Ideas.path}>
                    Submit Ideas
                  </Link>
                </a>
              </div>
            </div>
          </div>
          <div className="slide-content">
            <img src={image1} alt="Not in Senior Project Yet?" className="slide-img" />
            <div className="slide-text">
              <h3>Not in Senior Project Yet?</h3>
              <p>Find out What you should know</p>
              <a style={{ textAlign: 'center' }}>
                <Link to={RoutePaths.About.path}>
                  Read More
                </Link>
              </a>
            </div>
          </div>
        </Carousel>

        <Divider />

        <Title level={2}>Competition</Title>
        <Paragraph style={{ fontSize: '18px' }}>
          <Text strong></Text>
          &nbsp;The top 3 projects overall and the top 3 projects in research will be recognized. In addition, the top project in each of the
          following categories will be recognized: <Text code>Computing Security & Technology</Text>, <Text code>Corporate-Sponsored</Text>,{' '}
          <Text code>Entrepreneurial</Text>, <Text code>Humanitarian</Text>, <Text code>Gaming</Text>, and <Text code>Data Science</Text>
        </Paragraph>

        <Divider />

        <Title level={2}>Project Phases</Title>
        <Paragraph style={{ fontSize: '18px' }}>
          The project is divided into six phases:
        </Paragraph>
        <Row justify={'center'} style={{ padding: '40px 0 0 0' }}>
          <Col span={24}>
            <List
              grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 }}
              dataSource={CardListData}
              renderItem={(item) => (
                <List.Item style={{ width: screens.xs ? '100%' : 240, marginLeft: 'auto', marginRight: 'auto' }}>
                  <Card bordered={true} style={{ width: '250px', height: '300px' }} hoverable cover={<img alt="example" src={item.image} style={{ width: '250px', height: '150px' }} />}>
                    <Meta title={item.title} description={item.description} />
                  </Card>
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Divider />
      </Typography>
    </>
  );
};
