import { Skeleton } from 'antd';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HandleErrorFromBackend, ServerURL, SetDefaultAxiosHeaders } from '../constants';
import { StudentAPI } from '../type-defs';
import { MemberInfo } from './member-info';

interface MemberInfoContainerProps {}
export const MemberInfoContainer: FC<MemberInfoContainerProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [memberInfo, setMemberInfo] = useState<StudentAPI>();
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        SetDefaultAxiosHeaders();
        await axios.get(`${ServerURL}/api/currentUser/`).then((res) => {
          setMemberInfo(res.data);
        });
      } catch (error: any) {
        HandleErrorFromBackend(error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [location]);

  const onUpdatePhoto = async (member: StudentAPI, photo: string | undefined) => {
    const updatedMemberInfo = {
      ...member,
      user: {
        ...member.user,
        photo: photo ?? null,
      },
    };
    // call api to update photo

    await axios
      .put(`${ServerURL}/api/student/${member.user.id}/`, updatedMemberInfo)
      .then((res) => {
        setMemberInfo(updatedMemberInfo);
      })
      .catch((err) => {
        HandleErrorFromBackend(err);
      });
  };

  if (loading) return <Skeleton active />;

  if (!memberInfo) return <></>;

  return <MemberInfo info={memberInfo} onUpdatePhoto={onUpdatePhoto} />;
};
