import { message, Typography } from 'antd';
import axios from 'axios';
import { RoutePaths } from './route-definitions';

// export const ServerURL = 'https://44.209.53.159.nip.io'; //prod
export const ServerURL = process.env.REACT_APP_SERVER_URL; //dev

export const CurrentYear = 2023;
export const AvatarSize = 60;

export const APIs = {
  Login: `${ServerURL}/api/auth/`,
  // Logout: `${ServerURL}/api/logout/`,
  Register: `${ServerURL}/api/register/`,
  Students: `${ServerURL}/api/students/`,
  Projects: `${ServerURL}/api/projects/`,
  ProjectDetail: `${ServerURL}/api/project/:projectid/`,
};

export const MenuItems = [
  { key: RoutePaths.Home.key, label: RoutePaths.Home.title },
  { key: RoutePaths.About.key, label: RoutePaths.About.title },
  { key: RoutePaths.StudentResources.key, label: RoutePaths.StudentResources.title },
  { key: RoutePaths.Projects.key, label: RoutePaths.Projects.title },
  { key: RoutePaths.ProjectCreation.key, label: RoutePaths.ProjectCreation.title },
  { key: RoutePaths.Judges.key, label: RoutePaths.Judges.title },
  { key: RoutePaths.Sponsors.key, label: RoutePaths.Sponsors.title },
  { key: RoutePaths.MemberInfo.key, label: RoutePaths.MemberInfo.title },
  { key: RoutePaths.Winners.key, label: RoutePaths.Winners.title },
  { key: RoutePaths.Ideas.key, label: RoutePaths.Ideas.title },
];

export const MAX_SCREEN_SHOT_COUNT = 5;

export const CreateWebsiteFormFieldNames = {
  Name: 'projectName',
  Category: 'projectCategory',
  Logo: 'logo',
  ShortDescription: 'oneLiner',
  LongDescription: 'abstract',
  Screenshots: 'screenshots',
  HasVideo: 'hasVideo',
  VideoUrl: 'video',
  SelectedMembers: 'selectedMembers',
  SelectedStakeholders: 'selectedStakeholders',
  SelectedAdvisors: 'selectedAdvisors',
  Memo: 'memo',
  ContactPoint: 'contact',
  Poster: 'poster',
  DigitalPoster: 'digitPoster',
};

export const ProjectCategories = [
  { key: 1, value: 'Corporate' },
  { key: 2, value: 'Research' },
  { key: 3, value: 'Humanitarian' },
  { key: 4, value: 'Security' },
  { key: 5, value: 'Entrepreneurial' },
  { key: 6, value: 'Gaming' },
];

export const SessionStorageVariables = {
  AuthToken: 'token',
  ProjectId: 'projectId',
  IsLeader: 'isLeader',
};

export const LocalStorageVariables = {
  PreviewWebsiteData: 'previewWebsiteData',
  ProjectCategories: 'projectCategories',
};

export const SetDefaultAxiosHeaders = () => {
  const token = sessionStorage.getItem(SessionStorageVariables.AuthToken);
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
  }
};

export const HandleErrorFromBackend = async (err: any) => {
  if (err.response?.data) {
    if (err.response.data.detail) {
      return message.error(err.response.data.detail);
    }
    if (err.response.status === 500) {
      return message.error('Internal Server Error');
    }
    let result: any = [];
    const errors = err.response.data as Record<string, string[]>;
    Object.keys(errors).forEach((key: string) => {
      errors[key].forEach((error: string) => {
        result.push(<Typography.Paragraph style={{ textAlign: 'left' }}>{error.charAt(0).toUpperCase() + error.slice(1)}</Typography.Paragraph>);
      });
    });
    result = (
      <ul style={{ listStyleType: 'disc' }}>
        {result.map((item: string) => (
          // capitalize first letter of each word

          <li>{item}</li>
        ))}
      </ul>
    );
    return message.error(result);
  }
  return message.error(err.message);
};

export const getKalturaVideoUrl = (videoUrl: string) => {
  const videoId = videoUrl.split('/').pop();
  return `https://cdnapisec.kaltura.com/p/1513041/sp/151304100/embedIframeJs/uiconf_id/43882971/partner_id/1513041?iframeembed=true&playerId=kaltura_player&entry_id=${videoId}`;
};
