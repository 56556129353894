import { FC } from 'react';
import { ProjectAPI, ProjectCategoryAPI, StudentAPI, UserAPI } from '../type-defs';
import { ProjectDetailShared } from './shared/project-detail-shared';

interface ProjectDetailsProps {
  project: ProjectAPI;
  selectedMembers: StudentAPI[];
  selectedAdvisors: UserAPI[];
  selectedStakeholders: UserAPI[];
  projectCategories: ProjectCategoryAPI[];
}
export const ProjectDetails: FC<ProjectDetailsProps> = (props) => {
  return (
    <ProjectDetailShared
      projectCategories={props.projectCategories}
      logo={props.project.logo ?? ''}
      year={props.project.year ?? undefined}
      projectName={props.project.projectName ?? ''}
      projectCategory={props.project.projectCategory ?? 0}
      screenshots={props.project.screenshots ?? ''}
      oneLiner={props.project.oneLiner ?? ''}
      abstract={props.project.abstract ?? ''}
      video={props.project.video ?? ''}
      selectedMembers={props.selectedMembers}
      selectedAdvisors={props.selectedAdvisors}
      selectedStakeholders={props.selectedStakeholders}
      memo={props.project.memo ?? ''}
      contact={props.project.contact ?? ''}
      poster={props.project.poster ?? ''}
      digitPoster={props.project.digitPoster ?? ''}
      
    />
  );
};
