import { FC } from 'react';
import rubric from '../files/rubric.pdf';
import ppt from '../files/RequirementsReview_3.ppt';
import waved1 from "../files/WAVED_ATP_4-29.pdf"
import waved2 from "../files/WAVED_DD_4-29.pdf"
import waved3 from "../files/WAVED_SRS_4-29.pdf"
import WebsiteInstruction from "../files/Senior_Project_Website_Instructions.pdf";
import Title from 'antd/lib/typography/Title';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../route-definitions';

interface StudentResourcesProps {}

export const StudentResources: FC<StudentResourcesProps> = (props) => {
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <Title><strong>Student Resources</strong></Title>
      <p>On this page students can find helpful resources and examples to help guide them through their own senior projects and can be referred back to at any time</p>
      </div>
      <div>
        <p>
          <li><a href={waved3}>Example Software Requirements Specification PDF</a></li>
          <li><a href={waved2}>Example Design Specification PDF</a></li>
          <li><a href={waved1}>Example Acceptance Test Plan PDF</a></li>
          <li><a href={rubric}>Grading Rubric PDF</a></li>
          <li><a href={ppt}>Requirements Review Power Point</a></li>
          <li>
            <Link to={RoutePaths.WebInstructions.path}>
            Senior Project Website Instructions
            </Link>
          </li>
          {/*<li><a href={WebsiteInstruction}>Senior Project Website Instructions</a></li>*/}
        </p>
      </div>
      <div>
        <p>Pre-recorded video presentation Information:</p>
        <p>
        Students should create a pre-recorded video presentation no longer than twenty minutes (but close to twenty). It can be created in any manner the team and faculty advisor feel appropriate. The video will be public facing and is to be loaded to the Drexel servers and anyone with a link to the video will be able to view it. So, if you have sensitive corporate information, please clear it with the stakeholder to ensure it's ok to disclose it. The instructions for loading the video are: <a href="https://drexel.edu/it/help/a-z/kaltura/">Here</a>. The instructions for recording with Kultura can be found here. Once the video is created your project leader can add the Kultura link to the project creation page so that it will appear on your project's page
        </p>
      </div>
      <div>
        <p>Project and Team Formation:</p>

        <p>Standard teams can be made up of 5 to 6 CCI students from the same section of Senior Project. Teams should be diverse; therefore it is strongly encouraged that teams are formed with students form multiple degree programs. Students are expected to either select a pre-approved project or come up with their own idea. However, if student come up with their own concept the project must be approved by their instructor. They must also find an external stakeholder. Senior Project is meant to teach good software engineering principles and part of that process is learning to deal with a stakeholder.</p>

        <p>Multidisciplinary projects are strongly encouraged. There are no rules related to the composition of a team for teams with students completely from CCI. Any mix of majors is acceptable. Collaborating with Digital Media groups in the past has led to very interesting projects. Given the structure of Digital Media projects and CCI group in most cases will operate as two disjoint but collaborative teams. Both teams must have a minimum of 5 members. The Digital Media groups will be graded by Digital Media faculty and the CCI team will graded by CCI faculty. The CCI faculty will look to the Digital Faculty for guidance in terms of scope.</p>

        <p>Multidisciplinary projects with other colleges within the university are also encouraged. These projects are approved on a case by case basis. Two options exist. The CCI student joins another college's Senior Project/Design and the CCI student follows the project schedule for that college. Their grade is determined primarily by the other college's professor and procedures. The student will still register for the CCI senior project courses and will a) meet periodically with the CCI instructor to discuss their contribution to the project, b) submit their work products to the CCI instructor for review. Ideally, more than one CCI student would join the team. The second option is to create a collaborative, but disjoint team from the team outside of CCI. The team would work closely with the other team, but follow the guidelines of a traditional CCI team.</p>
      </div>
      <div>
      <p>Student Resources:</p>
      <p>

      <li>Writing Intensive Courses - These three courses are writing intensive which means that you are engaging in the process of writing and revising. The writing center is available to support you <a href="http://www.drexel.edu/writingcenter">Here</a>.</li>
      <li>Research Advice - Individualized advising on research and APA citing practice for your papers is available from the CCI Librarian at <a href="siftar@drexel.edus">iftar@drexel.edu</a>.</li>
      </p>

      <p>University Policies:</p> 
      <p>
      <li>Academic Honesty and Integrity: Plagiarism, fabrication, cheating, and other forms of academic dishonesty will not be tolerated. The university policies are available at: <a href="http://www.drexel.edu/studentlife/community_standards/studentHandbook/general_information/code_of_conduct/">Here</a> and <a href="http://www.drexel.edu/provost/policies/academic_dishonesty.asp">Here</a> </li>
      <li>Accommodation of Special Needs - Students with disabilities requesting accommodations and services at Drexel University need to present a current accommodation verification letter (AVL) to faculty before accommodations can be made. AVL's are issued by the Office of Disability Services (ODS). For additional information, contact ODS at 3201 Arch St., Street, Suite 210, Philadelphia, PA 19104, 215.895.1401 (V), or 215.895.2299 (TTY). <a href="http://www.drexel.edu/oed/disabilityResources/students/">Here</a> Students requiring any accommodation are strongly encouraged to discuss this situation with the instructor during the first week of the term.</li> 
      <li>Course Drop: The University policy is available at: <a href="http://www.drexel.edu/provost/policies/course_drop.asp">Here</a> </li>
      <li>Grade of Incomplete - Incomplete grades are contingent upon instructor approval and will only be considered in extenuating circumstances beyond a student's control. The instructor is under no obligation to offer an incomplete grade. At least 80% of the graded coursework must have already been completed in order for an incomplete grade to be considered. An incomplete contract with due date for delivery of the completed work should be completed by the student and the instructor. The form can be found here: <a href="http://drexel.edu/cci/resources/current-students/undergraduate/forms/">Here</a> </li>
      <li>Intellectual Property: In general, the Drexel policy assigns ownership of IP from senior projects to the students unless there is a contract or other material support such as research funding involved. If you have questions, the Drexel tech transfer office will discuss your situation with you. <a href="http://www.drexel.edu/provost/policies/patent_policy.asp"> Here</a></li>
      </p>

      </div>
    </div>
  );
};
