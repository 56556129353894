import { AutoComplete } from 'antd';
import { FC, useState } from 'react';

export type SearchBarOptionType = {
  user_id?: number;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  };
};
interface SearchBarProps {
  onSelect: (id: number) => void;
  options: SearchBarOptionType[];
}
export const SearchBar: FC<SearchBarProps> = (props) => {
  const [searchValue, setSearchValue] = useState('');
  return (
    <AutoComplete
      allowClear
      style={{ width: '100%' }}
      options={props.options.map((item) => ({ value: `${item.user.id}`, label: `${item.user.first_name} ${item.user.last_name} (${item.user.email})` }))}
      placeholder="Search..."
      filterOption={(inputValue, option) => option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
      onSelect={(item: string) => {
        const selected = props.options.find((option) => option.user.id.toString() === item);
        props.onSelect(selected?.user?.id ?? -1);
        setSearchValue('');
      }}
      onChange={setSearchValue}
      onClear={() => {
        setSearchValue('');
      }}
      value={searchValue}
    />

    // <Select
    //   onFocus={props.onFocus}
    //   style={{ width: '100%' }}
    //   mode="multiple"
    //   placeholder="Search for team members..."
    //   onSearch={(value) => props.onSearch(value)}
    //   dropdownRender={() => (
    //     <>
    //       {props.options.length === 0 ? (
    //         <Empty key="Members" description={<>No more data</>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    //       ) : (
    //         props.options.map((item) => (
    //           <Row
    //             style={{ alignItems: 'baseline' }}
    //             key={item.id}
    //             onMouseDown={(e) => {
    //               e.preventDefault();
    //               e.stopPropagation();
    //             }}
    //           >
    //             <Col flex="auto" style={{ padding: '0 15px' }}>
    //               <Button
    //                 style={{ width: '100%', textAlign: 'left', border: '0px', borderBottom: '1px solid' }}
    //                 type="default"
    //                 onClick={() => props.onSelect(item)}
    //               >
    //                 <PlusSquareOutlined />
    //                 {`${item.name} (${item.username})`}
    //               </Button>
    //             </Col>
    //           </Row>
    //         ))
    //       )}
    //     </>
    //   )}
    // />
  );
};
