import { Button, Drawer, Typography } from 'antd';
import { FC } from 'react';
import { MenuItems } from '../../constants';
import { RoutePaths } from '../../route-definitions';

interface MenuDrawerProps {
  onSelectMenu: (routeKey: string) => void;
  onCloseMenuDrawer: () => void;
  isOpen: boolean;
  isAuthenticated: boolean;
  isLeader: boolean;
}
export const MenuDrawer: FC<MenuDrawerProps> = (props) => {
  const getMenuItem = (item: any) => {
    if (item.key === RoutePaths.ProjectCreation.key && (!props.isAuthenticated || !props.isLeader)) {
      return null;
    } else if (item.key === RoutePaths.MemberInfo.key && (!props.isAuthenticated || props.isLeader)) {
      return null;
    }

    return (
      <Button
        type="default"
        key={item.key}
        onClick={() => props.onSelectMenu(item.key)}
        style={{
          display: 'block',
          width: '100%',
          textAlign: 'left',
          border: '0px',
          borderBottom: '3px solid #d1d1d1',
        }}
      >
        <Typography.Paragraph strong>{item.label}</Typography.Paragraph>
      </Button>
    );
  };

  return (
    <Drawer title="Menu" placement="right" onClose={props.onCloseMenuDrawer} open={props.isOpen}>
      {MenuItems.map((item) => getMenuItem(item))}
      {props.isAuthenticated ? (
        <Button
          type="primary"
          key={RoutePaths.Logout.key}
          onClick={() => props.onSelectMenu(RoutePaths.Logout.key)}
          style={{
            display: 'block',
            width: '100%',

            // textAlign: 'left',
            border: '0px',
            // borderBottom: '3px solid #d1d1d1',
          }}
        >
          <Typography.Paragraph strong style={{ color: 'white' }}>
            {RoutePaths.Logout.title}
          </Typography.Paragraph>
        </Button>
      ) : (
        <Button
          type="primary"
          key={RoutePaths.Login.key}
          onClick={() => props.onSelectMenu(RoutePaths.Login.key)}
          style={{
            display: 'block',
            width: '100%',

            // textAlign: 'left',
            border: '0px',
            // borderBottom: '3px solid #d1d1d1',
          }}
        >
          <Typography.Paragraph strong style={{ color: 'white' }}>
            {RoutePaths.Login.title}
          </Typography.Paragraph>
        </Button>
      )}
    </Drawer>
  );
};
