import { message, Modal, Skeleton } from 'antd';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CurrentYear, HandleErrorFromBackend, ServerURL, SessionStorageVariables, SetDefaultAxiosHeaders } from '../constants';
import { ProjectAPI, ProjectCategoryAPI, StudentAPI, TeamMemberAPI, UserAPI } from '../type-defs';
import { CreateWebsite } from './create-website';
import { AddStakeholderForm } from './shared/add-edit-stakeholder-form';

interface CreateWebsiteContainerProps {}
export const CreateWebsiteContainer: FC<CreateWebsiteContainerProps> = (props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [projectData, setProjectData] = useState<ProjectAPI>();
  const [projectCategories, setProjectCategories] = useState<ProjectCategoryAPI[]>([]);
  const [studentList, setStudentList] = useState<StudentAPI[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<StudentAPI[]>([]);

  const [advisorList, setAdvisorList] = useState<UserAPI[]>([]);
  const [selectedAdvisors, setSelectedAdvisors] = useState<UserAPI[]>([]);

  const [stakeholderList, setStakeholderList] = useState<UserAPI[]>([]);
  const [selectedStakeholders, setSelectedStakeholders] = useState<UserAPI[]>([]);
  const [isAddNewStakeholderModalOpen, setIsAddNewStakeholderModalOpen] = useState(false);
  const [beingEditedStakeholder, setBeingEditedStakeholder] = useState<UserAPI | undefined>(undefined);

  const [projectId] = useState(sessionStorage.getItem(SessionStorageVariables.ProjectId));

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      try {
        SetDefaultAxiosHeaders();
        // get student list for student dropdown
        await axios.get(`${ServerURL}/api/students/`).then(async (studentsResponse) => {
          setStudentList(studentsResponse.data);
          // get selected members
          await axios.get(`${ServerURL}/api/project/members/${projectId}/`).then((teamMemberResponse) => {
            // convert TeamMemberAPI to StudentAPI
            const temp: StudentAPI[] = [];
            teamMemberResponse.data.forEach((member: TeamMemberAPI) => {
              const student = studentsResponse.data?.find((student: StudentAPI) => student.user.id === member.profileID);
              if (student) {
                temp.push(student);
              }
            });
            setSelectedMembers(temp);
          });
        });

        // get advisor list for advisor dropdown
        await axios.get(`${ServerURL}/api/users/`).then(async (usersResponse) => {
          const tempAdvisors: UserAPI[] = usersResponse.data.filter((user: UserAPI) => user.is_advisor);
          const tempStakeholders: UserAPI[] = usersResponse.data.filter((user: UserAPI) => user.is_stakeholder);
          setAdvisorList(tempAdvisors);
          setStakeholderList(tempStakeholders);
          // get selected advisors
          await axios.get(`${ServerURL}/api/project/advisors/${projectId}/`).then((response) => {
            // convert response data type to UserAPI
            const temp: UserAPI[] = [];
            response.data.forEach((item: any) => {
              const advisor = tempAdvisors.find((advisor: UserAPI) => advisor.user.id === item.profileID);
              if (advisor) {
                temp.push(advisor);
              }
            });
            setSelectedAdvisors(temp);
          });

          // get selected stakeholders
          await axios.get(`${ServerURL}/api/project/stakeholders/${projectId}/`).then((response) => {
            // convert response data type to UserAPI
            const temp: UserAPI[] = [];
            response.data.forEach((item: any) => {
              const stakeholder = tempStakeholders.find((stakeholder: UserAPI) => stakeholder.user.id === item.profileID);
              if (stakeholder) {
                temp.push(stakeholder);
              }
            });
            setSelectedStakeholders(temp);
          });
        });

        // get  project data
        await axios.get(`${ServerURL}/api/project/${projectId}/`).then((res) => {
          setProjectData(res.data);
        });

        // get project categories
        await axios.get(`${ServerURL}/api/projectcate/`).then((res) => {
          setProjectCategories(res.data);
        });
      } catch (error: any) {
        const errorMessage = error.response?.data?.detail || '';
        if (errorMessage) {
          // token expired
          message.error(errorMessage);
        } else {
          message.error('Something went wrong');
        }
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [location]);

  if (loading) {
    return <Skeleton active />;
  }

  const onSubmit = async (data: ProjectAPI) => {
    console.log(data);
    // call api/project to update project data
    SetDefaultAxiosHeaders();
    await axios
      .put(`${ServerURL}/api/project/${projectId}/`, data)
      .then((res) => {
        message.success('Project saved successfully');
      })
      .catch((error) => {
        HandleErrorFromBackend(error);
      });
  };

  const onAddMember = async (profileId: number) => {
    // call api/teams to add member
    if (projectId) {
      SetDefaultAxiosHeaders();
      try {
        await axios.post(`${ServerURL}/api/teams/`, {
          projectID: parseInt(projectId),
          profileID: profileId,
          is_leader: false,
        });

        const member = studentList?.find((m) => m.user.id === profileId);
        if (member) {
          setSelectedMembers([...selectedMembers, member]);
        }

        message.success('Member added.');
        return true;
      } catch (error) {
        message.error('Cannot add this member.');
        return false;
      }
    }
    return false;
  };

  const onRemoveMember = async (profileId: number) => {
    // call api/teams to delete member
    if (projectId) {
      SetDefaultAxiosHeaders();
      try {
        await axios.delete(`${ServerURL}/api/team/${profileId}/`);
        const temp = selectedMembers?.filter((m) => m.user.id !== profileId);
        setSelectedMembers(temp);
        message.success('Member removed.');
        return true;
      } catch (error) {
        message.error('Cannot remove this member.');
        return false;
      }
    }
    return false;
  };

  const updateMemberPhoto = async (member: StudentAPI) => {
    const temp = [...selectedMembers];
    const index = temp.findIndex((m) => m.user.id === member.user.id);
    if (index !== -1) {
      temp[index] = member;
      setSelectedMembers(temp);
    }
  };

  const onAddStakeholder = () => {
    setBeingEditedStakeholder(undefined);
    setIsAddNewStakeholderModalOpen(true);
  };

  const onEditStakeholder = (id: number) => {
    const tempStakeholder = selectedStakeholders.find((stakeholder) => stakeholder.user.id === id);
    if (tempStakeholder) {
      setBeingEditedStakeholder(tempStakeholder);
      setIsAddNewStakeholderModalOpen(true);
    }
  };

  const onAddEditStakeholderFormSubmit = async (values: any) => {
    SetDefaultAxiosHeaders();
    if (values.id) {
      // update
      try {
        // call POST: api/users/ to add stakeholder
        await axios
          .put(`${ServerURL}/api/user/${values.id}/`, {
            user: {
              ...values,
              photo: values.photo ? values.photo[0].url : undefined,
              year: CurrentYear,
            },

            is_stakeholder: true,
            is_advisor: false,
            is_judge: false,
          })
          .then(async (res) => {
            // update selected stakeholder list
            const temp: UserAPI[] = [...selectedStakeholders];
            const index = temp.findIndex((stakeholder) => stakeholder.user.id === values.id);
            if (index >= 0) {
              temp[index] = res.data;
            }
            setSelectedStakeholders(temp);
            message.success('Stakeholder updated.');
            setIsAddNewStakeholderModalOpen(false);
          });
      } catch (error) {
        HandleErrorFromBackend(error);
      }
    } else {
      // add new
      try {
        // call POST: api/users/ to add stakeholder
        await axios
          .post(`${ServerURL}/api/users/`, {
            user: {
              ...values,
              photo: values.photo ? values.photo[0].url : undefined,
              year: CurrentYear,
            },

            is_stakeholder: true,
            is_advisor: false,
            is_judge: false,
          })
          .then(async (res) => {
            if (projectId) {
              await axios
                .post(`${ServerURL}/api/project/stakeholders/`, {
                  projectID: parseInt(projectId),
                  profileID: res.data?.user?.id,
                })
                .then(() => {
                  // add new stakeholder to selected stakeholder list
                  const temp: UserAPI[] = [...selectedStakeholders];
                  temp.push({
                    user: {
                      id: res.data?.user?.id,
                      first_name: values.first_name,
                      last_name: values.last_name,
                      email: values.email,
                      photo: values.photo ? values.photo[0].url : undefined,
                    },
                  });
                  setSelectedStakeholders(temp);

                  message.success('Stakeholder added.');
                  setIsAddNewStakeholderModalOpen(false);
                });
            }
          });
      } catch (error) {
        HandleErrorFromBackend(error);
      }
    }
  };

  const onRemoveStakeholder = async (profileID: number) => {
    if (projectId) {
      SetDefaultAxiosHeaders();
      try {
        await axios.delete(`${ServerURL}/api/project/stakeholder/${profileID}/?projectID=${projectId}`);
        // remove selected stakeholder from selected stakeholder list
        const temp: UserAPI[] = [...selectedStakeholders];
        const index = temp.findIndex((stakeholder) => stakeholder.user.id === profileID);
        if (index >= 0) {
          temp.splice(index, 1);
        }
        setSelectedStakeholders(temp);
        message.success('Stake holder removed.');
        return true;
      } catch (error) {
        message.error('Cannot remove this stake holder.');
        return false;
      }
    }
    return false;
  };

  const onAddAdvisor = async (profileId: number) => {
    if (projectId) {
      SetDefaultAxiosHeaders();
      try {
        await axios.post(`${ServerURL}/api/project/advisors/`, {
          projectID: parseInt(projectId),
          profileID: profileId,
        });
        // add new advisor to selected advisor list
        const temp: UserAPI[] = [...selectedAdvisors];
        const advisor = advisorList.find((advisor) => advisor.user.id === profileId);
        if (advisor) {
          temp.push(advisor);
        }
        message.success('Advisor added.');

        return true;
      } catch (error) {
        message.error('Cannot add this advisor.');
        return false;
      }
    }
    return false;
  };

  const onRemoveAdvisor = async (profileID: number) => {
    if (projectId) {
      SetDefaultAxiosHeaders();
      try {
        await axios.delete(`${ServerURL}/api/project/advisor/${profileID}/?projectID=${projectId}`);
        message.success('Advisor removed.');
        return true;
      } catch (error) {
        message.error('Cannot remove this advisor.');
        return false;
      }
    }
    return false;
  };

  return (
    <>
      <CreateWebsite
        projectData={projectData}
        studentList={studentList}
        selectedMembers={selectedMembers}
        onAddMember={onAddMember}
        onRemoveMember={onRemoveMember}
        updateMemberPhoto={updateMemberPhoto}
        advisorList={advisorList}
        selectedAdvisors={selectedAdvisors}
        onAddAdvisor={onAddAdvisor}
        onRemoveAdvisor={onRemoveAdvisor}
        selectedStakeholders={selectedStakeholders}
        onAddStakeholder={onAddStakeholder}
        onRemoveStakeholder={onRemoveStakeholder}
        onEditStakeholder={onEditStakeholder}
        onSubmit={onSubmit}
        projectCategories={projectCategories}
      />

      <Modal
        title={beingEditedStakeholder ? 'Edit Stakeholder' : 'Add New Stakeholder'}
        open={isAddNewStakeholderModalOpen}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => {
          setIsAddNewStakeholderModalOpen(false);
        }}
      >
        <AddStakeholderForm onAddEditStakeholderFormSubmit={onAddEditStakeholderFormSubmit} data={beingEditedStakeholder?.user} />
      </Modal>
    </>
  );
};
