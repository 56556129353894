import { Button, Card, Popover, Typography } from 'antd';
import { FC } from 'react';
import { ProjectAPI, ProjectCategoryAPI } from '../../type-defs';

interface ProjectCardProps {
  project: ProjectAPI;
  projectCategories: ProjectCategoryAPI[];
  onProjectDetailClick: (id: any) => void;
}
export const ProjectCard: FC<ProjectCardProps> = (props) => {
  return (
    <>
      <Card
        key={props.project.id}
        style={{ cursor: 'pointer', minHeight: '100%', padding: '0px' }}
        onClick={() => props.onProjectDetailClick(props.project.id)}
        hoverable={true}
      >
        <img
          src={props.project.logo ?? 'https://via.placeholder.com/300x300.png?text=Logo+Missing'}
          style={{ maxHeight: '140px', borderRadius: '10px', margin: '15px 0px', border: '1px solid #000' }}
          alt="Project logo"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.alt = 'Project logo';
            currentTarget.src = 'https://via.placeholder.com/300x300.png?text=Logo+Broken';
          }}
        />
        <h2 style={{ fontWeight: 'bold', textAlign: 'center', lineHeight: '120%' }}>{props.project.projectName}</h2>
        <h3>Category: {props.projectCategories.find((item) => item.id === props.project.projectCategory)?.projectCategory}</h3>
        <p style={{ color: 'black' }}>{props.project.oneLiner}</p>
      </Card>
      {props.project.memo ? (
        <>
          <Popover
            title={'Missing member(s)'}
            content={
              <>
                <Typography.Paragraph>{props.project.memo}</Typography.Paragraph>
                <Typography.Paragraph>
                  Please contact us at{' '}
                  <a href={`mailto:${props.project.contact}?subject=Request to join team ${props.project.projectName}`}>{props.project.contact}</a> if
                  you are interested.
                </Typography.Paragraph>
                {/* <Typography.Paragraph>
                  Please contact our team leader ABC at{' '}
                  <a href="mailto:abc@gmail.com?subject=Senior%20Project-Request%20to%20join%20team">abc@gmail.com</a> if you are interested.
                </Typography.Paragraph> */}
              </>
            }
          >
            <Button style={{ borderRadius: '10px', width: '100%' }} type="primary" danger>
              Missing Member(s)
            </Button>
          </Popover>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
