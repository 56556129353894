import { SmileOutlined } from '@ant-design/icons';
import { Card, Divider, Image, Timeline, Typography } from 'antd';
import { FC, useState } from 'react';
import lockdownSolutions from '../images/aboutProjects/lockdownSolutions.png';
import recursionError from '../images/aboutProjects/recursionError.png';
import neoprompt from '../images/aboutProjects/neoprompt.png';
import player2 from '../images/aboutProjects/player2.png';
import nlp from '../images/aboutProjects/nlp.png';
import graphIt from '../images/aboutProjects/graphIt.png';
import SeniorProjectShowcase from "../images/SeniorProjectShowcase.png";
import franklinInstitute from '../images/aboutProjects/franklinInstitute.png';
import jobMapr from '../images/aboutProjects/jobMapr.png';
import canopyIo from '../images/aboutProjects/canopyIo.jpg';
import crime360 from '../images/aboutProjects/crime360.png';
import crystalBall from '../images/aboutProjects/crystalBall.png';
import gsfcSpacecraft from '../images/aboutProjects/gsfcSpacecraft.png';
import soundForm from '../images/aboutProjects/soundForm.png';
import lattice from '../images/aboutProjects/lattice.png';
import sproutingSpirit from '../images/aboutProjects/sproutingSpirits.png';
import firmwareInfirmary from '../images/aboutProjects/firmwareInfirmary.png';
import collabArt from '../images/aboutProjects/collabArt.png';
import meGo from '../images/aboutProjects/meGo.png';
import automatedFish from '../images/aboutProjects/automatedFish.png';
import synner from '../images/aboutProjects/synner.png';
import torchFL from '../images/aboutProjects/torchFL.png';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../route-definitions';

const { Title } = Typography;
const { Meta } = Card;

interface WinnersProps { }

interface Winner { }

export const Winners: FC<WinnersProps> = (props) => {
  const [winners, setWinners] = useState<Winner[]>([]);
  return (
    <>
      <Typography>
        <Image src={SeniorProjectShowcase} alt="Senior Project Showcase" />
        <Title style={{ textAlign: 'center' }}>2022-2023 Senior Project Showcase Winners</Title>
        <p style={{ textAlign: 'center' }}>Event took place on June 7th, 2023 at 3675 Market Street in Philadelphia, PA</p>

        <h1 style={{ textAlign: 'center' }}>Overall Winners:</h1>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div>
            <h3>First Place (Winner)</h3>
            <a href="/projects/5/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Recursion Error" src={recursionError} />}>
                <Meta title="Recursion Error" />
              </Card>
            </a>
          </div>
          <div>
            <h3>2nd Place (Runner-up)</h3>
            <a href="/projects/13/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Lockdown Solutions" src={lockdownSolutions} />}>
                <Meta title="Lockdown Solutions" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Third Place (Runner-up)</h3>
            <a href="/projects/67/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="GraphIt" src={graphIt} />}>
                <Meta title="GraphIt" />
              </Card>
            </a>
          </div>
        </div>
        <br />
        <hr />
        <br />

        <h1 style={{ textAlign: 'center' }}>Computing Security & Technology:</h1>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div>
            <h3>First Place (Winner)</h3>
            <a href="/projects/67/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="GraphIt" src={graphIt} />}>
                <Meta title="GraphIt" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Second Place (Runner-up)</h3>
            <a href="/projects/69/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Franklin Institute Internal/External Penetration Test" src={franklinInstitute} />}>
                <Meta title="Franklin Institute Internal/External Penetration Test" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Third Place (Runner-up)</h3>
            <a href="/projects/30/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Canopy.io" src={canopyIo} />}>
                <Meta title="Canopy.io" />
              </Card>
            </a>
          </div>
        </div>


        <Divider />
        <h1 style={{ textAlign: 'center' }}>Corporate Sponsored</h1>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div>
            <h3>First Place (Winner)</h3>
            <a href="/projects/21/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Player 2 - Web App" src={player2} />}>
                <Meta title="Player 2 - Web App" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Second Place (Runner-up)</h3>
            <a href="/projects/47/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Crystal Ball" src={crystalBall} />}>
                <Meta title="Crystal Ball" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Third Place (Runner-up)</h3>
            <a href="/projects/22/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="GSFC Spacecraft" src={gsfcSpacecraft} />}>
                <Meta title="GSFC Spacecraft" />
              </Card>
            </a>
          </div>
        </div>


        <Divider />
        <h1 style={{ textAlign: 'center' }}>Data Science</h1>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div>
            <h3>First Place (Winner)</h3>
            <a href="/projects/40/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="NLP for Stock Market Predicition" src={nlp} />}>
                <Meta title="NLP for Stock Market Predicition" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Second Place (Runner-up)</h3>
            <a href="/projects/36/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="JobMapr" src={jobMapr} />}>
                <Meta title="JobMapr" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Third Place (Runner-up)</h3>
            <a href="/projects/29/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Crime360" src={crime360} />}>
                <Meta title="Crime360" />
              </Card>
            </a>
          </div>
        </div>


        <Divider />
        <h1 style={{ textAlign: 'center' }}>Entrepreneurial</h1>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div>
            <h3>First Place (Winner)</h3>
            <a href="/projects/13/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Lockdown Solutions" src={lockdownSolutions} />}>
                <Meta title="Lockdown Solutions" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Second Place (Runner-up)</h3>
            <a href="/projects/28/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="SoundForm X" src={soundForm} />}>
                <Meta title="SoundForm X" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Third Place (Runner-up)</h3>
            <a href="/projects/64/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Lattice" src={lattice} />}>
                <Meta title="Lattice" />
              </Card>
            </a>
          </div>
        </div>


        <Divider />
        <h1 style={{ textAlign: 'center' }}>Gaming</h1>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div>
            <h3>First Place (Winner)</h3>
            <a href="/projects/5/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Recursion Error" src={recursionError} />}>
                <Meta title="Recursion Error" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Second Place (Runner-up)</h3>
            <a href="/projects/39/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Sprouting Spirit: Dream Duo" src={sproutingSpirit} />}>
                <Meta title="Sprouting Spirit: Dream Duo" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Third Place (Runner-up)</h3>
            <a href="/projects/9/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Firmware Infirmary" src={firmwareInfirmary} />}>
                <Meta title="Firmware Infirmary" />
              </Card>
            </a>
          </div>
        </div>


        <Divider />
        <h1 style={{ textAlign: 'center' }}>Humanitarian</h1>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div>
            <h3>First Place (Winner)</h3>
            <a href="/projects/73/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Neoprompt" src={neoprompt} />}>
                <Meta title="Neoprompt" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Second Place (Runner-up)</h3>
            <a href="/projects/7/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="CollabArt" src={collabArt} />}>
                <Meta title="CollabArt" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Third Place (Runner-up)</h3>
            <a href="/projects/18/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="meGo" src={meGo} />}>
                <Meta title="meGo" />
              </Card>
            </a>
          </div>
        </div>
        <Divider />
        <h1 style={{ textAlign: 'center' }}>Research</h1>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div>
            <h3>First Place (Winner)</h3>
            <a href="/projects/65/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Synner" src={synner} />}>
                <Meta title="Synner" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Second Place (Runner-up)</h3>
            <a href="/projects/42/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Automated Fish Shape Analysis for Machine Learning" src={automatedFish} />}>
                <Meta title="Automated Fish Shape Analysis for Machine Learning" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Third Place (Runner-up)</h3>
            <a href="/projects/54/">
              <Card hoverable style={{ width: 200 }} cover={<img alt="torchFL" src={torchFL} />}>
                <Meta title="TorchFL" />
              </Card>
            </a>
          </div>
        </div>

      </Typography>
    </>
  );
};
