import { Descriptions } from 'antd';
import { FC } from 'react';
import { StudentAPI } from '../type-defs';
import { FileUpload } from './shared/file-upload';

interface MemberInfoProps {
  info: StudentAPI;
  onUpdatePhoto: (memberInfo: StudentAPI, photo: string | undefined) => void;
}
export const MemberInfo: FC<MemberInfoProps> = (props) => {
  return (
    <>
      <Descriptions title="Your Info:" bordered column={1}>
        <Descriptions.Item label="First Name">{props.info.user.first_name}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{props.info.user.last_name}</Descriptions.Item>
        <Descriptions.Item label="Email">{props.info.user.email}</Descriptions.Item>
        <Descriptions.Item label="Photo">
          <FileUpload
            prompt="Upload Photo"
            value={
              props.info.user.photo
                ? [
                    {
                      uid: props.info.user.photo,
                      name: props.info.user.photo,
                      status: 'done',
                      url: props.info.user.photo,
                    },
                  ]
                : []
            }
            onChange={(value) => {
              props.onUpdatePhoto(props.info, value && value.length > 0 ? value[0].url : undefined);
            }}
            errorMessage="You can only upload JPG/JPEG/PNG file."
            acceptedFileTypes="image/jpg,image/jpeg,image/png"
            listType="picture-card"
          />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
