import { Typography } from 'antd';
import { FC, useState } from 'react';
import './sponsors.css';
const { Title, Text } = Typography;

interface IdeasProps {}

interface Idea {
  name: 'bloomberg';
  logoUrl: '../images/bloomberg.png';
  website: 'https://www.bloomberg.com';
}

export const Ideas: FC<IdeasProps> = (props) => {
  const [sponsors, setSponsors] = useState<Idea[]>([
    {
      name: 'bloomberg',
      logoUrl: '../images/bloomberg.png',
      website: 'https://www.bloomberg.com',
    },
  ]);

  return (
    <>
      <Typography>
        <p>Stakeholders are the lifeblood of our program. While student inspired ideas are excellent, they often lack domain knowledge in the area of their project. They also miss out on developing a valuable skill, interacting with a client. Therefore, the College of Computing and Informatics reaches out to industry, government, and academia for ideas and guidance of our Senior Design projects.</p>
        <p>A stakeholder's involvement can vary based on interest. They can propose the idea of the project or help a student group nurture their idea from a concept into a practical project. We like stakeholders to stay in touch with the group. Usually this can be a short (or longer if you prefer) weekly or biweekly meeting via a telecommunication method of your choice. In person meeting can happen as well, but in general virtual is better for ongoing meetings. It is also possible for a stakeholder to attend the weekly meetings with the students/faculty advisor. Meeting focus initially on requirements specification, but can include technical advice (if applicable) as the project matures.</p>
        <p>Intellectual property can be retained by the stakeholder or solutions can be open sourced. It's also a great way for a stakeholder’s company to gain exposure to highly talented would-be graduates. Most typical costs are covered by CCI (ie. AWS fees during development), but if there are other costs of development (i.e. equipment or license purchases) they are typically covered by the stakeholder.</p>
        <p>To get started as a stakeholder, all you need is an idea and a one - two paragraph abstract.</p>
        <p>For more information contact Jeff Salvage (<a href="jks29@drexel.edu">jks29@drexel.edu</a>)</p>
      
      </Typography>
      
    </>
  );
};
