import { Button, Checkbox, Col, Form, Grid, Input, message, Row, Select, Tooltip, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { CreateWebsiteFormFieldNames, LocalStorageVariables } from '../constants';
import { RoutePaths } from '../route-definitions';
import { CreateWebsiteFormData, ProjectAPI, ProjectCategoryAPI, StudentAPI, UserAPI } from '../type-defs';
import { FileUpload } from './shared/file-upload';
import { GroupBox } from './shared/group-box';
import { SearchAddStudents } from './shared/search-add-students';
import { SearchAddUsers } from './shared/search-add-users';
import { StakeholderList } from './shared/stakeholder-list';
const { TextArea } = Input;
const { Option } = Select;
const { useBreakpoint } = Grid;

interface CreateWebsiteProps {
  projectData?: ProjectAPI;
  studentList?: StudentAPI[];
  selectedMembers?: StudentAPI[];
  onAddMember?: (profileId: number) => Promise<boolean>;
  onRemoveMember?: (profileId: number) => Promise<boolean>;
  updateMemberPhoto: (member: StudentAPI) => void;

  advisorList?: UserAPI[];
  selectedAdvisors?: UserAPI[];
  onAddAdvisor?: (profileId: number) => Promise<boolean>;
  onRemoveAdvisor?: (profileID: number) => Promise<boolean>;

  selectedStakeholders?: UserAPI[];
  onAddStakeholder: () => void;
  onRemoveStakeholder?: (profileID: number) => Promise<boolean>;
  onEditStakeholder: (profileID: number) => void;

  projectCategories?: ProjectCategoryAPI[];

  onSubmit?: (data: ProjectAPI) => Promise<void>;
}

export const CreateWebsite: FC<CreateWebsiteProps> = (props) => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const hasVideo = Form.useWatch('hasVideo', form);

  const [selectedStakeholders, setSelectedStakeholders] = useState<UserAPI[]>([]);

  const [selectedAdvisors, setSelectedAdvisors] = useState<UserAPI[]>([]);

  // populate data
  useEffect(() => {
    if (props.projectData) {
      form.setFieldsValue({
        ...props.projectData,
        hasVideo: props.projectData?.video ?? false,
        logo: props.projectData?.logo
          ? [
              {
                uid: props.projectData.logo,
                name: props.projectData.logo,
                status: 'done',
                url: props.projectData.logo,
              },
            ]
          : undefined,
        screenshots: props.projectData?.screenshots?.split(';').map((screenshot) => {
          return {
            uid: screenshot,
            name: screenshot,
            status: 'done',
            url: screenshot,
          };
        }),
        poster: props.projectData?.poster && [
          {
            uid: props.projectData.poster,
            name: props.projectData.poster,
            status: 'done',
            url: props.projectData.poster,
          },
        ],
        digitPoster: props.projectData?.digitPoster && [
          {
            uid: props.projectData.digitPoster,
            name: props.projectData.digitPoster,
            status: 'done',

            url: props.projectData.digitPoster,
          },
        ],
      });
    }
  }, [props.projectData]);

  useEffect(() => {
    // set selected members
    if (props.selectedMembers) {
      form.setFieldsValue({ ...form.getFieldsValue(), selectedMembers: props.selectedMembers });
      // setSelectedMembers(props.selectedMembers);
    }
  }, [props.selectedMembers]);

  useEffect(() => {
    // set selected advisors
    if (props.selectedAdvisors) {
      form.setFieldsValue({ ...form.getFieldsValue(), selectedAdvisors: props.selectedAdvisors });
      setSelectedAdvisors(props.selectedAdvisors);
    }
  }, [props.selectedAdvisors]);

  useEffect(() => {
    // set selected stakeholders
    if (props.selectedStakeholders) {
      form.setFieldsValue({ ...form.getFieldsValue(), selectedStakeholders: props.selectedStakeholders });
      setSelectedStakeholders(props.selectedStakeholders);
    }
  }, [props.selectedStakeholders]);

  useEffect(() => {
    form.validateFields([
      CreateWebsiteFormFieldNames.SelectedAdvisors,
      CreateWebsiteFormFieldNames.SelectedStakeholders,
      CreateWebsiteFormFieldNames.SelectedMembers,
    ]);
  }, [selectedAdvisors, props.selectedMembers, selectedStakeholders]);

  // members handlers
  const onAddMember = async (id: number) => {
    // call api to add member
    await props.onAddMember?.(id);
  };

  const onRemoveMember = async (id: number) => {
    // call api to remove member
    await props.onRemoveMember?.(id);
  };

  // stakeholders handlers
  const onAddStakeholder = () => {
    props.onAddStakeholder();
  };

  const onRemoveStakeholder = async (id: number) => {
    await props.onRemoveStakeholder?.(id).then((res) => {
      if (res) {
        // remove stakeholder from selected stakeholder
        const temp = selectedStakeholders.filter((m) => m.user.id !== id);
        form.setFieldValue(CreateWebsiteFormFieldNames.SelectedStakeholders, temp);
        setSelectedStakeholders(temp);
      }
    });
  };

  const onEditStakeholder = (id: number) => {
    props.onEditStakeholder(id);
  };

  // advisors handlers
  const addAdvisor = async (id: number) => {
    // call api to add member
    await props.onAddAdvisor?.(id).then((res) => {
      if (res) {
        // find member by id
        const member = props.advisorList?.find((m) => m.user.id === id);
        if (member) {
          form.setFieldValue(CreateWebsiteFormFieldNames.SelectedAdvisors, selectedAdvisors.concat(member));
          setSelectedAdvisors([...selectedAdvisors, member]);
        }
      }
    });
  };
  const onRemoveAdvisor = async (profileID: number) => {
    await props.onRemoveAdvisor?.(profileID).then((res) => {
      if (res) {
        // remove advisor from selected advisors
        const temp = selectedAdvisors.filter((m) => m.user.id !== profileID);
        form.setFieldValue(CreateWebsiteFormFieldNames.SelectedAdvisors, temp);
        setSelectedAdvisors(temp);
      }
    });
  };

  // submit
  const onSubmitCreateWebsite = (values: CreateWebsiteFormData) => {
    console.log('form data:');
    console.log(values);
    // prepare data to send to api
    const data: ProjectAPI = {
      ...values,
      memo: values.memo ?? null,
      logo: values.logo && values.logo.length > 0 ? values.logo[0].url : null,
      screenshots:
        values.screenshots && values.screenshots.length > 0
          ? values.screenshots
              .map((screenshot) => {
                return screenshot.url;
              })
              .join(';')
          : null,
      poster: values.poster && values.poster.length > 0 ? values.poster[0].url : null,
      digitPoster: values.digitPoster && values.digitPoster.length > 0 ? values.digitPoster[0].url : null,
    };
    props.onSubmit?.(data);
  };

  const previewWebsite = () => {
    // add selectedStakeholders to form
    const temp = { ...form.getFieldsValue(), selectedStakeholders } as CreateWebsiteFormData;
    console.log(temp);
    form.setFieldsValue(temp);
    localStorage.setItem(LocalStorageVariables.PreviewWebsiteData, JSON.stringify(temp));
    window.open(RoutePaths.PreviewWebsite.path);
  };

  const onLookingForMembersCheckChanged = (checked: boolean) => {
    if (!checked) {
      form.setFieldValue(CreateWebsiteFormFieldNames.Memo, null);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error('Please fill in all required fields.');
  };

  return (
    <>
      {/* Project info */}
      <Typography.Title level={3}>Senior Design Project | 2022-2023</Typography.Title>
      <Form form={form} layout="vertical" onFinish={onSubmitCreateWebsite} onFinishFailed={onFinishFailed}>
        <Row gutter={screens.xs || !screens.xl ? [0, 0] : [30, 15]} style={{ width: '100%' }}>
          {/* name */}
          <Col xs={24} xl={8}>
            <Form.Item
              label="Project Name:"
              name={['projectName']}
              rules={[
                {
                  required: true,
                  message: 'Please enter project name.',
                },
              ]}
            >
              <Input placeholder="Project Name" />
            </Form.Item>
          </Col>
          {/* category */}
          <Col xs={24} xl={8}>
            <Form.Item
              label="Project Category:"
              name={CreateWebsiteFormFieldNames.Category}
              rules={[
                {
                  required: true,
                  message: 'Please enter project category.',
                },
              ]}
            >
              <Select placeholder="Select a category">
                {props.projectCategories?.map((cate) => (
                  <Option key={cate.id} value={cate.id}>
                    {cate.projectCategory}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* logo */}
          <Col xs={24} xl={8}>
            <Form.Item
              label="Project Logo:"
              name={CreateWebsiteFormFieldNames.Logo}
              rules={[
                {
                  required: true,
                  message: 'Please upload project logo.',
                },
              ]}
            >
              <FileUpload
                errorMessage="You can only upload JPG/JPEG/PNG file."
                acceptedFileTypes="image/jpg,image/jpeg,image/png"
                listType="picture-card"
                maxCount={1}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* short description */}
        <Tooltip title="Describe your project in one sentence.">
          <Form.Item
            label="Short Description:"
            name={CreateWebsiteFormFieldNames.ShortDescription}
            rules={[
              {
                required: true,
                message: 'Please enter some description.',
              },
            ]}
          >
            <Input placeholder="Short Description" maxLength={280} />
          </Form.Item>
        </Tooltip>
        {/* long description */}
        <Tooltip title="Give more information about your project.">
          <Form.Item
            label="Long Description:"
            name={CreateWebsiteFormFieldNames.LongDescription}
            rules={[{ required: true, message: 'Please enter long description' }]}
          >
            <TextArea rows={4} placeholder="Long Description" />
          </Form.Item>
        </Tooltip>

        <Row>
          <Col>
            <Form.Item label="" name={CreateWebsiteFormFieldNames.HasVideo} valuePropName="checked">
              <Checkbox>Do you have a video presentation for your project?</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item
              label=""
              name={CreateWebsiteFormFieldNames.VideoUrl}
              rules={[
                {
                  required: hasVideo,
                  message: 'Please enter video url.',
                },
              ]}
            >
              <Input placeholder="Video Presentation URL" disabled={!hasVideo} />
            </Form.Item>
          </Col>
        </Row>
        <Typography.Paragraph>Please upload screenshots of your project:</Typography.Paragraph>
        <Form.Item
          name={CreateWebsiteFormFieldNames.Screenshots}
          style={{ textAlignLast: 'center' }}
          rules={[
            {
              required: true,
              message: 'Please upload screenshots.',
            },
          ]}
        >
          <FileUpload
            errorMessage="You can only upload JPG/JPEG/PNG file."
            acceptedFileTypes="image/png,image/jpeg,image/jpg"
            listType="picture-card"
            maxCount={5}
          />
        </Form.Item>
        <Row>
          <Col>
            <Form.Item label="Poster: (Please upload PDF file only)" name={CreateWebsiteFormFieldNames.Poster}>
              <FileUpload
                errorMessage="You can only upload PDF file."
                acceptedFileTypes="application/pdf"
                listType="text"
                maxCount={1}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Digital Poster: (Please upload image file (JPG, JPEG, or PNG) only)" name={CreateWebsiteFormFieldNames.DigitalPoster}>
              <FileUpload
                errorMessage="You can only upload JPG/JPEG/PNG file."
                acceptedFileTypes="image/png,image/jpeg,image/jpg"
                listType="text"
                maxCount={1}
              />
            </Form.Item>
          </Col>
        </Row>

        <br />

        {/* Team members */}
        <SearchAddStudents
          title="Team Members"
          subtitle="Added Members"
          allStudentList={props.studentList ?? []}
          selectedAccounts={props.selectedMembers ?? []}
          onAdd={onAddMember}
          onRemove={onRemoveMember}
          updateMemberPhoto={props.updateMemberPhoto}
          formItemName={CreateWebsiteFormFieldNames.SelectedMembers}
          formItemErrorMessage="Please select at least one team member."
          isRequired
          onLookingForMembersCheckChanged={onLookingForMembersCheckChanged}
          memo={props.projectData?.memo}
        />
        <br />
        {/* Advisors */}
        <SearchAddUsers
          title="Advisors"
          subtitle="Added Advisors"
          items={props.advisorList ?? []}
          selectedAccounts={selectedAdvisors}
          onAdd={addAdvisor}
          onRemove={onRemoveAdvisor}
          formItemName={CreateWebsiteFormFieldNames.SelectedAdvisors}
          formItemErrorMessage="Please select at least one advisor."
          isRequired
        />
        <br />
        {/* Stake Holders */}
        <GroupBox
          title={<Typography.Title level={3}>Stakeholders</Typography.Title>}
          titleBackground={'white'}
          titleColor={'black'}
          content={
            <>
              <StakeholderList
                selectedStakeholders={selectedStakeholders}
                onAddStakeholder={onAddStakeholder}
                onRemoveStakeholder={onRemoveStakeholder}
                onEditStakeholder={onEditStakeholder}
              />
            </>
          }
        />

        {/* review & submit button */}
        <br />
        <Row justify="center" gutter={[20, 0]}>
          <Col style={{ textAlign: 'center' }} xs={24} xl={6} lg={8} md={10}>
            <Button style={{ width: '100%', height: '50px' }} onClick={previewWebsite}>
              Preview Project Website
            </Button>
            <Typography.Paragraph style={{ padding: '5px 10px', width: '100%' }}>Click to preview how your website looks</Typography.Paragraph>
          </Col>
          <Col style={{ textAlign: 'center' }} xs={24} xl={6} lg={8} md={10}>
            <Form.Item>
              <Button
                type="primary"
                style={{
                  width: '100%',
                  height: '50px',
                  backgroundColor: '#ffc600',
                  color: '#232d38',
                  border: '0px',
                }}
                htmlType="submit"
              >
                Save Project Website
              </Button>
            </Form.Item>
            {/* <Typography.Paragraph style={{ padding: '5px 10px', width: '100%' }}>
              Once clicked, the information you entered will be sent for review to be published on the website.
            </Typography.Paragraph> */}
          </Col>
        </Row>
      </Form>
    </>
  );
};
