import { Menu } from 'antd';
import { FC } from 'react';
import { RoutePaths } from '../../route-definitions';

interface DesktopMenuProps {
  isAuthenticated: boolean;
  isLeader: boolean;
  onSelectMenu: (routeKey: string) => void;
  selectedMenu?: string[];
}
export const DesktopMenu: FC<DesktopMenuProps> = (props) => {
  return (
    <Menu
      style={{
        width: '100%',
        paddingTop: '0px',
        display: 'flex',
        justifyContent: 'end',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={[RoutePaths.Home.key]}
      onSelect={(item: any) => props.onSelectMenu(item.key)}
      selectedKeys={props.selectedMenu}
    >
      <Menu.Item key={RoutePaths.Home.key}>{RoutePaths.Home.title}</Menu.Item>
      <Menu.Item key={RoutePaths.About.key}>{RoutePaths.About.title}</Menu.Item>
      <Menu.Item key={RoutePaths.StudentResources.key}>{RoutePaths.StudentResources.title}</Menu.Item>
      <Menu.Item key={RoutePaths.Projects.key}>{RoutePaths.Projects.title}</Menu.Item>
      {props.isAuthenticated && props.isLeader && <Menu.Item key={RoutePaths.ProjectCreation.key}>{RoutePaths.ProjectCreation.title}</Menu.Item>}
      {props.isAuthenticated && !props.isLeader && <Menu.Item key={RoutePaths.MemberInfo.key}>{RoutePaths.MemberInfo.title}</Menu.Item>}
      <Menu.Item key={RoutePaths.Judges.key}>{RoutePaths.Judges.title}</Menu.Item>
      <Menu.Item key={RoutePaths.Sponsors.key}>{RoutePaths.Sponsors.title}</Menu.Item>
      {props.isAuthenticated ? (
        <Menu.Item key={RoutePaths.Logout.key}>{RoutePaths.Logout.title}</Menu.Item>
      ) : (
        <Menu.Item key={RoutePaths.Login.key}>{RoutePaths.Login.title}</Menu.Item>
      )}
    </Menu>
  );
};
