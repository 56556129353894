import { Typography } from 'antd';
import { FC, useState } from 'react';
import './sponsors.css';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../route-definitions';
import image1 from "../images/Instructions/Picture1.png"
import image2 from "../images/Instructions/Picture2.png"
import image3 from "../images/Instructions/Picture3.png"
import image4 from "../images/Instructions/Picture4.png"
import image5 from "../images/Instructions/Picture5.png"
import image6 from "../images/Instructions/Picture6.png"
import image7 from "../images/Instructions/Picture7.png"

const { Title, Text } = Typography;

interface webInstructionProps {}

export const WebInstructions: FC<webInstructionProps> = (props) => {

  return (
    <>
      <Typography>
        <Title level={1} style={{ textAlign: 'center' }}>
        Drexel Senior Project Website Creation Guide
        </Title>

        <ol>
            <li><b>Step 1:</b> To begin, visit the new Senior Project website <a href="http://seniorproject.cci.drexel.edu/">here</a>. Note: This website is still in development and you may experience bugs. Please report any bugs to the Senior Project Website Team.</li>
            <li><b>Step 2:</b> The Team Leader, and only the Team Leader, should create an account from the Login page located in the top right-hand corner.</li>
              <img src={image1} alt="Picture 1" style={{width: "100%"}} />
              <p></p>
              <li><b>Step 3:</b> Once you have navigated to the Login page, click on "Register" (Picture 1). This will take you to the next screen (Picture 2).</li>
            <div style={{flexDirection: "row", justifyContent: "space-evenly"}}>
              <img src={image2} alt="Picture 2" style={{height: "500px", width: "500px", padding: "50px"}} />
              <img src={image3} alt="Picture 3" style={{height: "500px", width: "500px"}} />
            <p></p>
            </div>
            <li><b>Step 4:</b> To verify students, you must enter the extended version (not the more common shortened version) of your student email address. To find this extended email address, log into DrexelOne and select "Account" (Picture 1) in the top right-hand corner. Then, select "Personal Information." The correct form of the email to use will be below your name and Student ID (Picture 2).</li>
            <div style={{flexDirection: "row", justifyContent: "space-evenly"}}>
              <img src={image4} alt="Picture 4" style={{height: "200px", width: "500px", padding: "50px"}} />
              <img src={image5} alt="Picture 5" style={{height: "200px", width: "500px"}} />
              <p></p>
            </div>
            <li><b>Step 5:</b> Once all of the fields have been filled in, select "Register." This will automatically sign you in, and you should be automatically sent to the project creation page. If you are not redirected, there should be a new tab on the navigation bar labeled "Project Creation." When you are on the Project Creation page, fill out your project information and make sure to add all your team members to the project.
                <ul>
                    <li><b>Step 5.1:</b> All images uploaded on the site should be 500x500 pixels, but large images can be cropped and edited once uploaded to the site.</li>
                    <li><b>Step 5.2:</b> If your senior project has a stakeholder(s), you will need to create a profile for them by clicking the square with a plus sign on it.</li>
                    <div style={{flexDirection: "row", justifyContent: "space-evenly"}}>
                      <img src={image6} alt="Picture 6" style={{height: "500px", width: "400px", padding: "50px"}} />
                      <img src={image7} alt="Picture 7" style={{height: "700px", width: "500px"}} />
                      <p></p>
                    </div>
                </ul>
            </li>
            <li><b>Step 6:</b> Before you submit, you are able to preview what your page should look like before submission. If there are any mistakes with the submitted page return to the project creation to update any information</li>
            <li><b>Step 7:</b> After you have created your webpage near the end of the third quarter of the senior project you will need to record your presentation and add it to your webpage THERE IS A 20 MINUTE TIME LIMIT. The recording must be done through Kultura (Drexel Streams) in order to ensure the video is accessible in the future. If you are new to Kultura or need a refresher on how Kultura works please check out <a href="https://drexel.edu/it/help/a-z/kaltura/"> Here</a> for more information</li>
            <li><b>Step 8:</b> Projects that are entered into the Senior Showcase must also create a poster. The poster is due by Sunday night June 3rd. The poster must be submitted in two formats: a pdf and a 4K JPG. The poster must be in a landscape orientation (Wider than higher). The aspect ratio must be 16:9. Each project is free to highlight the most important aspects of their project. What type of information is included will vary based on the type of project presented. All projects should include an abstract / problem statement.  Projects that include development should highlight their architecture, while research projects should include their conclusions. Drexel has some basic poster presentation guidelines <a href='https://libguides.library.drexel.edu/poster' >here</a>. An example of a data science poster can be found <a href="https://wiki.smu.edu.sg/1617t1ISSS608g1/ISSS608_2016_17_T1_Project_Team_12_Poster">here</a>  </li>
        </ol>
        </Typography>
    </>
  );
};
