import { Typography } from 'antd';
import { FC, useState } from 'react';
import './sponsors.css';
const { Title, Text } = Typography;

interface SponsorsProps {}

interface Sponsor {
  name: 'bloomberg';
  logoUrl: '../images/bloomberg.png';
  website: 'https://www.bloomberg.com';
}

export const Sponsors: FC<SponsorsProps> = (props) => {
  const [sponsors, setSponsors] = useState<Sponsor[]>([
    {
      name: 'bloomberg',
      logoUrl: '../images/bloomberg.png',
      website: 'https://www.bloomberg.com',
    },
  ]);

  return (
    <>
      <Typography>
        <Title level={1} style={{ textAlign: 'center' }}>
          Our Sponsors
        </Title>

        <Title level={2} style={{ textAlign: 'center', paddingBottom: '40px' }}>
          The 2022-2023 Senior Project Showcase is presented by:
        </Title>
        <div className="center">
          <img src={require('../images/bloomberg.png')} alt="bloomberg" className="center" style={{ width: '85%' }} />
          <p>
            If you are interested in sponsoring Drexel's Senior Project Competition in the future please reach out to Professor Jeff Salvage at{' '}
            <Text strong>
              {' '}
              <a href="mailto:jks29@drexel.edu">jks29@drexel.edu</a>
            </Text>
          </p>
        </div>
      </Typography>
      {/*
      <div className="center">
        <p>Other Sponsors if needed..... (can be added here)</p>
        <img src={require('../images/bloomberg.png')} alt="bloomberg" className="center" style={{width: '85%', height: '300px'}} />
      </div>
      */}
    </>
  );
};
