import { Button, Form, Input, Modal, Space, Typography } from 'antd';
import { FC, useState } from 'react';
import { Register } from '../components/register';
import { useAuth } from '../utils/auth-context';

interface LoginProps {
  // onLogin: (username: string, password: string) => void;
}
export const Login: FC<LoginProps> = (props) => {
  const auth = useAuth();
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const login = (values: any) => {
    auth?.onLogin(values.username, values.password);
  };

  const onClickRegister = () => {
    setIsRegisterModalOpen(true);
  };

  return (
    <>
      <Form onFinish={(values) => login(values)}>
        <Form.Item name="username" label="Username" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="password" label="Password" rules={[{ required: true }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Space direction="horizontal">
            <Button type="primary" htmlType="submit">
              Login
            </Button>
            <Typography.Text>
              New user?
              <Button type="link" onClick={onClickRegister}>
                Register
              </Button>
            </Typography.Text>
          </Space>
        </Form.Item>
      </Form>

      <Modal title="Register" open={isRegisterModalOpen} onCancel={() => setIsRegisterModalOpen(false)} footer={null}>
        <Register />
      </Modal>
    </>
  );
};
