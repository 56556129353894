import axios from 'axios';
import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HandleErrorFromBackend, ServerURL, SessionStorageVariables, SetDefaultAxiosHeaders } from '../constants';
import { AuthContext } from './auth-context';
import { RoutePaths } from '../route-definitions';

interface AuthProviderProps {
  children: any;
}

// const fakeAuth = () =>
//   new Promise<string>((resolve) => {
//     setTimeout(() => resolve('2342f2f1d131rf12'), 250);
//   });

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const [token, setToken] = useState<string | undefined>();
  const [isLeader, setIsLeader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (username: string, password: string) => {
    axios
      .post(`${ServerURL}/api/auth/`, { username, password })
      .then(async (authRes) => {
        if (!authRes.data.token) {
          return;
        }

        setToken(authRes.data.token);
        sessionStorage.setItem(SessionStorageVariables.AuthToken, authRes.data.token ?? '');

        axios.defaults.headers.common['Authorization'] = `Token ${authRes.data.token}`;

        await axios.get(`${ServerURL}/api/currentUser/`).then(async (currentUserRes) => {
          const origin = location.state?.from?.pathname || RoutePaths.Home.path; // direct the user to the page he/she was trying to access before being redirected to the login page

          if (currentUserRes.data.is_leader) {
            setIsLeader(true);
            sessionStorage.setItem(SessionStorageVariables.IsLeader, 'true');
            // call api to get project id
            await axios.get(`${ServerURL}/api/projectid/`).then((projectidRes) => {
              sessionStorage.setItem(SessionStorageVariables.ProjectId, projectidRes.data[0].projectID ?? '');
            });
          } else {
            setIsLeader(false);
            sessionStorage.setItem(SessionStorageVariables.IsLeader, 'false');
          }
          // if success, redirect to the page he/she was trying to access before being redirected to the login page
          navigate(origin);
        });
      })
      .catch((error) => {
        // handle error from backend
        HandleErrorFromBackend(error);
      });
  };

  const handleLogout = async () => {
    setToken(undefined);
    SetDefaultAxiosHeaders();
    try {
      await axios.post(`${ServerURL}/api/logout/`);
      sessionStorage.removeItem(SessionStorageVariables.AuthToken);
      navigate(RoutePaths.Home.path);
    } catch (error) {
      // handle error from backend
      HandleErrorFromBackend(error);
    } 
  };  

  const value = {
    token: token,
    isLeader: isLeader,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};
