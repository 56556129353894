import { Col, Layout, Row, Typography } from 'antd';
import { FC } from 'react';
const { Footer } = Layout;

interface FooterProps { }
export const FooterComponent: FC<FooterProps> = () => {
  return (
    <Footer style={{ padding: '0px', display: 'flex', flexDirection: 'column' }}>
      <Col style={{ textAlign: 'center', backgroundColor: '#07294d', padding: '10px 0' }}>
        <Typography.Paragraph style={{ color: 'white' }}>Questions? Professor Jeff Salvage (<a href="mailto:jks29@drexel.edu">jks29@drexel.edu</a>)</Typography.Paragraph>
      </Col>
      <Row style={{ backgroundColor: '#1A252F', width: '100%', padding: '20px 0px' }}>
        <Col span={12} style={{ textAlign: 'center', padding: '1rem 0px 0px' }}>
          <Typography.Paragraph style={{ color: 'white' }}><a href="https://drexel.edu/privacy/" target={"blank"}>Privacy & legal</a></Typography.Paragraph>
        </Col>
        <Col span={12} style={{ textAlign: 'center', padding: '1rem 0px 0px' }}>
          <Typography.Paragraph style={{ color: 'white' }}>© 2023 Drexel University. All Rights Reserved.</Typography.Paragraph>
        </Col>
      </Row>
    </Footer>
  );
};
