import { FC } from 'react';

let containerStyle = {
  border: '1px solid lightgrey',
  padding: '10px',
  margin:"30px 0px"
};
let titleStyle = {
  display: 'table',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginTop: '-27px',
  marginLeft: '5px',
  fontSize: '14px',
  fontWeight: 500,
};

interface GroupBoxProps {
  title: string | JSX.Element | JSX.Element[] 
  titleBackground?: string;
  titleColor?: string;
  content: string | JSX.Element | JSX.Element[]

}

export const GroupBox: FC<GroupBoxProps> = (props) => {
  return (
    <>
      <div style={containerStyle}>
        <div
          style={Object.assign(
            {
              backgroundColor: props.titleBackground ?? 'white',
              color: props.titleColor ?? '#333333',

            },
            titleStyle
          )}
        >
          <b>{props.title}</b>
        </div>
        <div style={{padding:"20px"}}>{props.content}</div>
      </div>
    </>
  );
};
