import { Skeleton } from 'antd';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { HandleErrorFromBackend, ServerURL } from '../constants';
import { ProjectAPI, ProjectCategoryAPI, UserAPI } from '../type-defs';
import { Projects } from './projects';

interface ProjectsContainerProps {}

interface AdvisorProjectPair {
  id: number;
  profileID: number;
  projectID: number;
}

export const ProjectsContainer: FC<ProjectsContainerProps> = (props) => {
  
  const [projects, setProjects] = useState<ProjectAPI[]>([]);
  const [projectCategories, setProjectCategories] = useState<ProjectCategoryAPI[]>([]);
  const [advisors, setAdvisors] = useState<UserAPI[]>([]);
  const [loading, setLoading] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState<ProjectAPI[]>([]);
  const [advisorProjectPairs, setAdvisorProjectPairs] = useState([] as AdvisorProjectPair[]);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState('All');
  const [selectedAdvisorFilter, setSelectedAdvisorFilter] = useState('All');
  const yearFilters = process.env.REACT_APP_FILTER_BY_YEAR_OPTIONS
    ? process.env.REACT_APP_FILTER_BY_YEAR_OPTIONS.split(',').map((y) => {
        return { value: y, label: y };
      })
    : [];
  const [selectedYearFilter, setSelectedYearFilter] = useState(yearFilters[1].value);

  // const currentYear = new Date().getFullYear();
  // const currentYearOption = { value: currentYear.toString(), label: currentYear.toString() };
  

  const advisorFilters = [
    {
      value: 'All',
      label: `All`,
    },
    ...advisors.map((advisor) => ({
      value: advisor.user.id.toString(),
      label: `${advisor.user?.first_name} ${advisor.user?.last_name}`,
    })),
  ];

  const categoryFilters = [
    {
      value: 'All',
      label: `All`,
    },

    ...projectCategories.map((category) => ({
      value: category.id.toString(),
      label: `${category.projectCategory}`,
    })),
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${ServerURL}/api/projects/`);
        // only projects with logo, project name, 1 liner, year, and project category are shown
        const tempProjects = response.data.filter(
          (project: ProjectAPI) => project.logo && project.projectName && project.oneLiner && project.year && project.projectCategory
        ) as ProjectAPI[];
        setProjects(tempProjects);
        setFilteredProjects(tempProjects.filter((project) => project.year?.toString() === yearFilters[1].value));

        // get project categories
        const categoriesResponse = await axios.get(`${ServerURL}/api/projectcate/`);
        setProjectCategories(categoriesResponse.data);

        // get advisors
        await axios.get(`${ServerURL}/api/users/`).then(async (usersResponse) => {
          const tempAdvisors: UserAPI[] = usersResponse.data.filter((user: UserAPI) => user.is_advisor);
          setAdvisors(tempAdvisors);
        });

        // get advisor-project pairs
        await axios.get(`${ServerURL}/api/project/advisors/`).then(async (advisorProjectResponse) => {
          setAdvisorProjectPairs(advisorProjectResponse.data);
        });
      } catch (error: any) {
        // message.error('Something went wrong. Please try again later.');
        HandleErrorFromBackend(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getFilteredProjects = (year: string, categoryFilter: string, advisorFilter: string) => {
    console.log(categoryFilter, advisorFilter);
    let result = [] as ProjectAPI[];

    // apply year filter
    if (year === 'All') {
      result.push(...projects);
    } else {
      result.push(...projects.filter((project) => project.year?.toString() === year));
    }

    // apply category filter
    if (categoryFilter !== 'All') {
      result = result.filter((project) => project.projectCategory?.toString() === categoryFilter.toString());
    }

    // apply advisor filter
    if (advisorFilter === 'All') {
      return result;
    } else {
      const projectIdsByAdvisor = advisorProjectPairs.filter((pair) => pair.profileID.toString() === advisorFilter);
      const temp = result.filter((project) => {
        return projectIdsByAdvisor.find((pair) => pair.projectID === project.id);
      });
      return temp;
    }
  };

  const onSelectCategoryFilter = (categoryFilter: string) => {
    setSelectedCategoryFilter(categoryFilter);
    const tempFilteredProjects = getFilteredProjects(selectedYearFilter, categoryFilter, selectedAdvisorFilter);
    setFilteredProjects(tempFilteredProjects);
  };

  const onSelectAdvisorFilter = async (advisorFilter: string) => {
    setSelectedAdvisorFilter(advisorFilter);
    const tempFilteredProjects = getFilteredProjects(selectedYearFilter, selectedCategoryFilter, advisorFilter);
    setFilteredProjects(tempFilteredProjects);
  };

  const onSelectYearFilter = (year: string) => {
    setSelectedYearFilter(year);
    const tempFilteredProjects = getFilteredProjects(year, selectedCategoryFilter, selectedAdvisorFilter);
    setFilteredProjects(tempFilteredProjects);
  };

  if (loading) return <Skeleton active />;

  return (
    <Projects
      projects={projects}
      onSelectCategoryFilter={onSelectCategoryFilter}
      onSelectAdvisorFilter={onSelectAdvisorFilter}
      onSelectYearFilter={onSelectYearFilter}
      categoryFilters={categoryFilters}
      advisorFilters={advisorFilters}
      filteredProjects={filteredProjects}
      projectCategories={projectCategories}
      yearFilters={yearFilters}
    />
  );
};
