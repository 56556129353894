import { Image, List, Skeleton } from 'antd';
import Title from 'antd/lib/typography/Title';
import { FC, useEffect, useState } from 'react';
import { ServerURL } from '../constants';

interface JudgesProps {}

interface DataType {
  user: {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    is_active: boolean;
    email: string;
    photo: string;
    year: number;
  };
  bio: string;
  affiliation: string;
}

const apiUrl = `${ServerURL}/api/judges/`;

export const Judges: FC<JudgesProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DataType[]>([]);

  useEffect(() => {
  fetch(apiUrl)
  .then((res) => res.json())
  .then((res) => {
  setData(res.sort((a: DataType, b: DataType) => a.user.last_name.localeCompare(b.user.last_name)));
  setLoading(false);
  })
  .catch((error) => {
  console.error('Error:', error);
  setLoading(false);
  });
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <Title><strong>About the Judges</strong></Title>
      </div>
      <p>CCI's Senior Project competition is assessed by a panel of external judges in two stages. The judges include Drexel alumni and representatives
        from industry. Each project team creates a 20 minute presentation that is evaluated on its nature, technical difficulty, impact, and how well
        the team followed software engineering principles including specification, design, implementation, testing, the project's functionality and
        the team's presentation style. These criteria are provided in the Final Presentation Judging Rubric (pdf or excel). to pick the best project
        in each category and top three research projects. The second phase of the evaluation is a live poster presentation by the selected groups
        where judges will ask questions. The scores of both parts of the competition are used to select overall winners.</p>
      <List
        className="demo-loadmore-list"
        loading={loading}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Skeleton avatar title={false} loading={loading} active>
              <List.Item.Meta
                avatar={
                  <Image
                    src={item.user.photo || 'https://via.placeholder.com/300x300.png?text=Profile'}
                    style={{ width: '100px', height: '100px' }}
                  />
                }
                title={<a href={item.user.email}>{item.user.first_name} {item.user.last_name} ({item.user.email})</a>}
                description={item.bio  || "Waiting for a Bio :) Hang Tight!"}
              />
              {/*<div>{item.user.email}</div>*/}
            </Skeleton>
          </List.Item>
        )}
      />
    </div>
  );
};
