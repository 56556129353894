/** @format */

import { MenuOutlined } from '@ant-design/icons';
import { Col, Grid, Image, Layout, Row, Typography } from 'antd';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { HandleErrorFromBackend, ServerURL } from '../../constants';
import logo from '../../images/CollegeComputingInformaticsLogo.png';
import BackgroundImage from '../../images/slider-2.png';
import { RoutePaths } from '../../route-definitions';
import { useAuth } from '../../utils/auth-context';
import { DesktopMenu } from './desktop-menu';
import { FooterComponent } from './footer';
import { MenuDrawer } from './mobile-menu-drawer';
const { useBreakpoint } = Grid;
const { Header, Content } = Layout;

interface MasterLayoutProps {}

export const MasterLayout: FC<MasterLayoutProps> = (props) => {
  const location = useLocation();
  const [subHeaderTitle, setSubHeaderTitle] = useState('');
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState<string[] | undefined>();
  const auth = useAuth();
  const screens = useBreakpoint();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLeader, setIsLeader] = useState(false);
  const [projectName, setProjectName] = useState("")
  const [token, setToken] = useState('');
  
  const { projectId } = useParams();


  useEffect(() => {

    const getProjectName = async () => {
      try {
        if (projectId) {
          // call project api  to get project name
          await axios.get(`${ServerURL}/api/project/${projectId}/`).then(async (projectRes) => {
            if (projectRes.data) {
              setProjectName(projectRes.data.projectName);
            }
          });
        }
      } catch (error) {
        HandleErrorFromBackend(error);
      }
      
    } 
    getProjectName();
    
  }, [projectId])
  

  useEffect(() => {
    if (auth?.token || sessionStorage.getItem('token')) {
      const temp = auth?.token ?? sessionStorage.getItem('token') ?? '';
      setToken(temp);
      // if access login, navigate to home
      if (location.pathname.toLowerCase() === RoutePaths.Login.path.toLowerCase()) {
        navigate(RoutePaths.Home.path);
      }
    } else {
      setToken('');
    }

    if (auth?.isLeader || sessionStorage.getItem('isLeader') === 'true') {
      setIsLeader(true);
    } else {
      setIsLeader(false);
    }
  }, [location]);

  // Set the subheader title based on the current route (when page is refreshed)
  useEffect(() => {
    const temp = location.pathname.toLowerCase().replace(/\/$/, '');
    switch (temp) {
      case RoutePaths.Home.path.toLowerCase():
        setSubHeaderTitle(RoutePaths.Home.title);
        setSelectedMenu([RoutePaths.Home.key]);
        break;
      case RoutePaths.About.path.toLowerCase():
        setSubHeaderTitle(RoutePaths.About.title);
        setSelectedMenu([RoutePaths.About.key]);
        break;
      case RoutePaths.StudentResources.path.toLowerCase():
        setSubHeaderTitle(RoutePaths.StudentResources.title);
        setSelectedMenu([RoutePaths.StudentResources.key]);
        break;
      case RoutePaths.Projects.path.toLowerCase():
        setSubHeaderTitle(RoutePaths.Projects.title);
        setSelectedMenu([RoutePaths.Projects.key]);
        break;
      case RoutePaths.Ideas.path.toLowerCase():
        setSubHeaderTitle(RoutePaths.Ideas.title);
        setSelectedMenu([RoutePaths.Ideas.key]);
        break;
      case RoutePaths.Judges.path.toLowerCase():
        setSubHeaderTitle(RoutePaths.Judges.title);
        setSelectedMenu([RoutePaths.Judges.key]);
        break;
      case RoutePaths.Sponsors.path.toLowerCase():
        setSubHeaderTitle(RoutePaths.Sponsors.title);
        setSelectedMenu([RoutePaths.Sponsors.key]);
        break;
      case RoutePaths.ProjectCreation.path.toLowerCase():
        setSubHeaderTitle(RoutePaths.ProjectCreation.title);
        setSelectedMenu([RoutePaths.ProjectCreation.key]);
        break;
      case RoutePaths.PreviewWebsite.path.toLowerCase():
        setSubHeaderTitle(RoutePaths.PreviewWebsite.title);
        setSelectedMenu([RoutePaths.PreviewWebsite.key]);
        break;
      case RoutePaths.MemberInfo.path.toLowerCase():
        setSubHeaderTitle(RoutePaths.MemberInfo.title);
        setSelectedMenu([RoutePaths.MemberInfo.key]);
        break;
      case RoutePaths.Login.path.toLowerCase():
        setSubHeaderTitle(RoutePaths.Login.title);
        setSelectedMenu([RoutePaths.Login.key]);
        break;
      case RoutePaths.Winners.path.toLowerCase():
        setSubHeaderTitle(RoutePaths.Winners.title);
        setSelectedMenu([RoutePaths.Winners.key]);
        break;
      case RoutePaths.WebInstructions.path.toLowerCase():
        setSubHeaderTitle(RoutePaths.WebInstructions.title);
        setSelectedMenu([RoutePaths.WebInstructions.key]);
        break;
      default:
        setSelectedMenu([]);
        break;
    }
  }, [location]);

  const onSelectMenu = (e: any) => {
    switch (e) {
      case RoutePaths.Home.key:
        navigate(RoutePaths.Home.path);
        setSubHeaderTitle(RoutePaths.Home.title);
        break;
      case RoutePaths.About.key:
        navigate(RoutePaths.About.path);
        setSubHeaderTitle(RoutePaths.About.title);
        break;
      case RoutePaths.StudentResources.key:
        navigate(RoutePaths.StudentResources.path);
        setSubHeaderTitle(RoutePaths.StudentResources.title);
        break;
      case RoutePaths.ProjectCreation.key:
        navigate(RoutePaths.ProjectCreation.path);
        setSubHeaderTitle(RoutePaths.ProjectCreation.title);
        break;
      case RoutePaths.Winners.key:
        navigate(RoutePaths.Winners.path);
        setSubHeaderTitle(RoutePaths.Winners.title);
        break;
      case RoutePaths.Projects.key:
        navigate(RoutePaths.Projects.path);
        setSubHeaderTitle(RoutePaths.Projects.title);
        break;
      case RoutePaths.Sponsors.key:
        navigate(RoutePaths.Sponsors.path);
        setSubHeaderTitle(RoutePaths.Sponsors.title);
        break;
      case RoutePaths.Ideas.key:
        navigate(RoutePaths.Ideas.path);
        setSubHeaderTitle(RoutePaths.Ideas.title);
        break;
      case RoutePaths.Judges.key:
        navigate(RoutePaths.Judges.path);
        setSubHeaderTitle(RoutePaths.Judges.title);
        break;
      case RoutePaths.MemberInfo.key:
        navigate(RoutePaths.MemberInfo.path);
        setSubHeaderTitle(RoutePaths.MemberInfo.title);
        break;
      case RoutePaths.WebInstructions.key:
        navigate(RoutePaths.WebInstructions.path);
        setSubHeaderTitle(RoutePaths.WebInstructions.title);
        break;
      case 'Login':
        navigate(RoutePaths.Login.path);
        setSubHeaderTitle(RoutePaths.Login.title);
        break;
      case 'Logout':
        auth?.onLogout();
        break;
      default:
        break;
    }
    setIsDrawerOpen(false);
  };

  const onCloseMenuDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Layout style={{ minHeight: '100vh', display: 'flex', minWidth: '375px', maxWidth: '100vw' }}>
        <Header
          style={{
            paddingLeft: screens.xs || !screens.lg ? '10px' : '24px',
            paddingRight: screens.xs || !screens.lg ? '10px' : '24px',
            paddingTop: '10px',
            paddingBottom: '10px',
            height: 'fit-content',
            backgroundColor: '#07294d',
          }}
        >
          <Row>
            <Col xs={15} sm={15} md={10} lg={9} xl={7}>
              <a target={'_blank'} href="https://drexel.edu/cci/" rel="noreferrer">
                <Image src={logo} preview={false} width="100%" />
              </a>
            </Col>
            <Col
              style={{ textAlign: 'end' }}
              xs={{ span: 8, offset: 1 }}
              sm={{ span: 8, offset: 1 }}
              md={{ span: 13, offset: 1 }}
              lg={{ span: 14, offset: 1 }}
              xl={{ span: 16, offset: 1 }}
            >
              {screens.xs || !screens.xl ? (
                <MenuOutlined style={{ color: 'white' }} onClick={() => setIsDrawerOpen(true)} /> // mobile
              ) : (
                <DesktopMenu isLeader={isLeader} isAuthenticated={token ? true : false} onSelectMenu={onSelectMenu} selectedMenu={selectedMenu} />
              )}
            </Col>
          </Row>
        </Header>
        <Row style={{ height: '200px' }}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundImage: 'url(' + BackgroundImage + ')',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Typography.Title
              level={2}
              style={{
                color: 'black',
                padding: '15px',
                backgroundColor: 'white',
                borderRadius: '10px',
                textAlign: 'center',
              }}
            >
              {projectId ? projectName : subHeaderTitle.toUpperCase()}
            </Typography.Title>
          </Col>
        </Row>
        <Content
          style={{
            margin: screens.xs || !screens.lg ? '10px' : '24px',
            backgroundColor: 'white',
            flex: '1',
            borderRadius: '10px',
            padding: screens.xs || !screens.lg ? '20px 20px' : '40px 50px',
          }}
        >
          <Outlet />
        </Content>
        <FooterComponent />
      </Layout>
      <MenuDrawer
        isLeader={isLeader}
        isOpen={isDrawerOpen}
        onCloseMenuDrawer={onCloseMenuDrawer}
        onSelectMenu={onSelectMenu}
        isAuthenticated={token ? true : false}
      />
    </>
  );
};
