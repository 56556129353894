import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Tooltip } from 'antd';
import axios from 'axios';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HandleErrorFromBackend, ServerURL, SessionStorageVariables } from '../constants';
import { useAuth } from '../utils/auth-context';

interface RegisterProps {}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

export const Register: FC<RegisterProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const PasswordRequirements = () => {
    return (
      <ul>
        <li>Must be at least 8 characters.</li>
        <li>Must not be entirely numeric.</li>
        <li>Must not be common like in "12345678" or "abc12345".</li>
      </ul>
    );
  };

  const UsernameRequirements = () => {
    return (
      <ul>
        <li>150 characters or fewer.</li>
        <li>Letters, digits and @/./+/-/_ only.</li>
      </ul>
    );
  };

  const EmailRequirements = () => {
    return (
      <ul>
        <li>Make sure to use your full drexel email.</li>
        <li>Go to Drexel one, and view your personal info</li>
        <li>example darth.vader@drexel.edu</li>
      </ul>
    );
  };

  const onRegister = async (values: any) => {
    setLoading(true);
    // call api to register
    await axios
      .post(`${ServerURL}/api/register/`, values)
      .then(async (res) => {
        sessionStorage.setItem(SessionStorageVariables.AuthToken, res.data.token);
        // if success, call login api
        await auth?.onLogin(values.username, values.password); // TODO: check if this is redundant because we already have the token
      })
      .catch((err) => {
        HandleErrorFromBackend(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form onFinish={onRegister} {...formItemLayout} preserve={true}>
      <Form.Item
        label={
          <>
            Username
            <Tooltip title={<UsernameRequirements />}>
              <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
            </Tooltip>
          </>
        }
        name="username"
        rules={[{ required: true, message: 'Please enter username' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={
          <>
            Password
            <Tooltip title={<PasswordRequirements />}>
              <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
            </Tooltip>
          </>
        }
        name="password"
        rules={[{ required: true, message: 'Please enter password' }]}
      >
        <Input.Password />
      </Form.Item>

      {/* <QuestionCircleOutlined /> */}
      <Form.Item
        label="Confirm Password"
        name="password2"
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label={
          <>
            Email
            <Tooltip title={<EmailRequirements />}>
              <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
            </Tooltip>
          </>
        }
        name="email"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <div style={{ textAlign: 'center' }}>
        <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
          Register
        </Button>
      </div>
    </Form>
  );
};
