import { message } from 'antd';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { ServerURL } from '../constants';
import { ProjectCategoryAPI } from '../type-defs';
import { PreviewWebsite } from './preview-website';

export const PreviewWebsiteContainer: FC<any> = (props) => {
  const [projectCatagories, setProjectCategories] = useState<ProjectCategoryAPI[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getProjectCategories = async () => {
      try {
        // get project categories
        await axios.get(`${ServerURL}/api/projectcate/`).then((res) => {
          setProjectCategories(res.data);
        });
      } catch (error: any) {
        message.error('Something went wrong. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    getProjectCategories();
  }, []);

  return <PreviewWebsite projectCategories={projectCatagories} />;
};
