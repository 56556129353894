/** @format */

import 'antd/dist/antd.min.css';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { MasterLayout } from './components/shared/master-layout';
import './fonts/style.css';
import { About } from './pages/about';
import { CreateWebsitePage } from './pages/create-website-page';
import { Home } from './pages/home';
import { Ideas } from './pages/ideas';
import { Judges } from './pages/judges';
import { Login } from './pages/login';
import { MemberInfoPage } from './pages/member-info-page';
import { PreviewWebsitePage } from './pages/preview-website-page';
import { ProjectDetailsPage } from './pages/project-details-page';
import { Projects } from './pages/projects-page';
import { Sponsors } from './pages/sponsors';
import { StudentResources } from './pages/student-resources';
import { Winners } from './pages/winners';
import { WebInstructions } from './pages/webInstructions';
import { RoutePaths } from './route-definitions';
import { AuthProvider } from './utils/auth-provider';
import { ProtectedRouteForLeader } from './utils/protected-route-for-leader';
import { ProtectedRouteForMember } from './utils/protected-route-for-member';

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path={''} element={<MasterLayout />}>
          <Route index element={<Home />} />
          <Route path={RoutePaths.About.path} element={<About />} />
          <Route path={RoutePaths.StudentResources.path} element={<StudentResources />} />
          <Route path={RoutePaths.Projects.path} element={<Projects />} />
          <Route path={RoutePaths.Judges.path} element={<Judges />} />
          <Route path={RoutePaths.Sponsors.path} element={<Sponsors />} />
          <Route path={RoutePaths.Login.path} element={<Login />} />
          <Route path={RoutePaths.Winners.path} element={<Winners />} />
          <Route path={RoutePaths.Ideas.path} element={<Ideas />} />
          <Route path={RoutePaths.WebInstructions.path} element={<WebInstructions />} />
          <Route
            path={RoutePaths.ProjectCreation.path}
            element={
              <ProtectedRouteForLeader>
                <CreateWebsitePage />
              </ProtectedRouteForLeader>
            }
          />
          <Route
            path={RoutePaths.PreviewWebsite.path}
            element={
              <ProtectedRouteForLeader>
                <PreviewWebsitePage />
              </ProtectedRouteForLeader>
            }
          />
          <Route
            path={RoutePaths.MemberInfo.path}
            element={
              <ProtectedRouteForMember>
                <MemberInfoPage />
              </ProtectedRouteForMember>
            }
          />

          <Route path={RoutePaths.ProjectDetails.path} element={<ProjectDetailsPage />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
};

export default App;
