import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Image, List, Popconfirm, Row, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { AvatarSize } from '../../constants';
import { UserAPI } from '../../type-defs';

interface StakeholderListProps {
  selectedStakeholders: UserAPI[];
  onAddStakeholder: () => void;
  onEditStakeholder: (id: number) => void;
  onRemoveStakeholder: (id: number) => void;
}

export const StakeholderList: FC<StakeholderListProps> = (props) => {
  const [listWithHead, setListWithHead] = useState<UserAPI[]>();
  useEffect(() => {
    setListWithHead([{ user: { id: -1 } }, ...props.selectedStakeholders]);
  }, [props.selectedStakeholders]);

  const onAddNewStakeholderClick = () => {
    props.onAddStakeholder();
  };
  return (
    <>
      <List
        style={{ textAlign: 'center' }}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 5,
          xxl: 6,
        }}
        // dataSource={props.selectedStakeholders}
        dataSource={listWithHead}
        renderItem={(item) =>
          item.user.id === -1 ? (
            <List.Item
              colStyle={{ height: '100%', paddingBottom: '16px' }}
              style={{
                cursor: 'pointer',
                backgroundColor: 'lightgrey',
                borderRadius: '20px',
                height: '100%',
              }}
              onClick={() => onAddNewStakeholderClick()}
            >
              <Card
                key={'addNew'}
                style={{
                  cursor: 'pointer',
                  backgroundColor: 'lightgrey',
                  borderRadius: '20px',
                  height: '100%',
                }}
                onClick={() => onAddNewStakeholderClick()}
                bodyStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
              >
                <PlusOutlined />
              </Card>
            </List.Item>
          ) : (
            <List.Item>
              <Card
              // style={{ width: "220px", height: "140px" }}
              >
                <Card.Meta
                  avatar={<Avatar size={AvatarSize} src={<Image src={item.user.photo} preview={{ mask: <EyeOutlined /> }} />} />}
                  title={
                    <Typography.Text style={{ whiteSpace: 'break-spaces', fontSize: '0.75rem' }}>
                      {item.user.first_name + ' ' + item.user.last_name}
                    </Typography.Text>
                  }
                />
                <br />
                <Typography.Text style={{ color: 'grey', fontSize: '0.75rem', whiteSpace: 'break-spaces' }}>{item.user.email}</Typography.Text>
              </Card>
              <Row>
                <Col span={12}>
                  <Button
                    type="primary"
                    style={{
                      width: '100%',
                      // borderRadius: '10px'
                    }}
                    onClick={() => props.onEditStakeholder(item.user.id)}
                  >
                    Edit
                  </Button>
                </Col>
                <Col span={12}>
                  <Popconfirm title="Are you sure you want to delete?" onConfirm={() => props.onRemoveStakeholder(item.user.id)}>
                    <Button
                      type="primary"
                      style={{
                        width: '100%',
                        // borderRadius: '10px'
                      }}
                      danger
                    >
                      Remove
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </List.Item>
          )
        }
      />
    </>
  );
};
