import { SmileOutlined } from '@ant-design/icons';
import { Card, Divider, Timeline, Typography } from 'antd';
import { FC } from 'react';
import cozyDragons from '../images/aboutProjects/cozyDragons.png';
import flourish from '../images/aboutProjects/flourish.png';
import frenzy from '../images/aboutProjects/frenzy.png';
import frugality from '../images/aboutProjects/frugality.png';
import metropolis from '../images/aboutProjects/metropolis.png';
import player2 from '../images/aboutProjects/player2.png';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../route-definitions';

const { Title, Text } = Typography;
const { Meta } = Card;

const data = [
  '7 months before the start of senior project',
  '1 month before the start of senior project',
  'Day 1 of senior project',
  '3 months in to senior project',
  '6 months in to senior project',
  'The end of senior project',
];

interface AboutProps {}

export const About: FC<AboutProps> = (props) => {
  return (
    <>
      <Typography>
        <Title style={{ textAlign: 'center' }}>What is Senior Project?</Title>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>
            Senior Project has changed a lot over the years. Success in Senior Project no longer starts the day before fall quarter. Typically, late
            in the winter quarter (sometimes it may be as late as the first week of the Spring quarter) an orientation meeting for Senior Project
            occurs. This may be online via Zoom or in person (or both). It is recorded for those that can't attend. This meeting highlights all the
            requirements and procedures related to Senior Project.
          </p>
          <p>
            Students in non-research projects are expected to form teams of 5-6 students. First and foremost, think about the people whom you wish to
            work with. Think of the line from Spamalot, “The success of every endeavor depends upon the people that you choose." This is very true in
            Senior Project. A group's success or failure is often determined by the selection of team members. So, while you are in your undergraduate
            classes seek out and work with other students that you think might make good Senior Project partners. Try to work in their group for
            Software Engineering type classes. Find out, ahead of time. If they will make good partners. Research projects are typically solo
            endeavors, but have specific requirements explained in the meeting.
          </p>
          <p>
            Project ideas may come from external stakeholders, which we provide many, or they may come from students themselves. If you are a group
            proposing an idea, you must have a legitimate stakeholder sponsoring the project (a domain expert or end user or your project).
            Stakeholders can take many forms and we are happy to work with you ahead of time to determine if a project / stakeholder is appropriate.
            You may also propose a project that is entrepreneurial in nature, but again there are additional requirements.
          </p>
        </div>
        <Divider />

        <Title level={2} style={{ textAlign: 'center' }}>
          Senior Project Timeline:
        </Title>
        <br />
        <Timeline mode="left">
          <Timeline.Item color="gray" label="7 Months Prior">
            <p>Begin planning and thinking about potential projects and team members.</p>
          </Timeline.Item>
          <Timeline.Item color="gray" label="1 Month Prior">
            <p>Be aligned with a project and prepare to start working.</p>
          </Timeline.Item>
          <Timeline.Item color="green" label="Day 1">
            <p>Begin work.</p>
          </Timeline.Item>
          <Timeline.Item color="green" label="3 Months After Start">
            <p>First quarter finished. Presentation.</p>
          </Timeline.Item>
          <Timeline.Item color="green" label="6 Months After Start">
            <p>Second quarter finished. Presentation.</p>
          </Timeline.Item>
          <Timeline.Item color="purple" label="End of Project" dot={<SmileOutlined />}>
            <p>Third quarter finished. Final Presentation and Competition</p>
          </Timeline.Item>
        </Timeline>
        <Divider />
        <Title style={{ textAlign: 'center' }}>Previous Project Examples (Winners from each category):</Title>
        <h3 style={{ textAlign: 'center' }}>
          <Link to={RoutePaths.Winners.path}>
            Here is a list of winners
          </Link>
        </h3>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <h3>Corporate:</h3>
            <a href="https://cci.drexel.edu/SeniorDesign/2021_2022/PLAYER_2/index.html">
              <Card hoverable style={{ width: 200 }} cover={<img alt="Player 2" src={player2} />}>
                <Meta title="PLAYER 2" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Research:</h3>
            <a href="https://cci.drexel.edu/SeniorDesign/2021_2022/FRUGALITY_OF_UNCAPACITATED_FACILITY_LOCATION/index.html">
              <Card hoverable style={{ width: 200 }} cover={<img alt="FRUGALITY OF UNCAPACITATED FACILITY LOCATION" src={frugality} />}>
                <Meta title="FRUGALITY OF LOCATION" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Humanitarian:</h3>
            <a href="https://cci.drexel.edu/SeniorDesign/2021_2022/METROPOLIS_IOT/index.html">
              <Card hoverable style={{ width: 200 }} cover={<img alt="METROPOLIS IOT" src={metropolis} />}>
                <Meta title="METROPOLIS IOT" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Security:</h3>
            <a href="https://cci.drexel.edu/SeniorDesign/2021_2022/COZY_DRAGONS/index.html">
              <Card hoverable style={{ width: 200 }} cover={<img alt="COZY DRAGONS" src={cozyDragons} />}>
                <Meta title="COZY DRAGONS" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Entrepreneurial:</h3>
            <a href="https://cci.drexel.edu/SeniorDesign/2021_2022/FLOURISH/index.html">
              <Card hoverable style={{ width: 200 }} cover={<img alt="FLOURISH" src={flourish} />}>
                <Meta title="FLOURISH" />
              </Card>
            </a>
          </div>
          <div>
            <h3>Gaming:</h3>
            <a href="https://cci.drexel.edu/SeniorDesign/2021_2022/FOOLS_GOLD_FRENZY/index.html">
              <Card hoverable style={{ width: 200 }} cover={<img alt="FOOLS GOLD FRENZY" src={frenzy} />}>
                <Meta title="FOOLS GOLD FRENZY" />
              </Card>
            </a>
          </div>
        </div>
      </Typography>
    </>
  );
};
