import { Col, Empty, List, Row, Select, Space, Typography } from 'antd';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../route-definitions';
import { ProjectAPI, ProjectCategoryAPI } from '../type-defs';
import { ProjectCard } from './shared/project-card';
const { Title, Text } = Typography;

interface ProjectsProps {
  onSelectCategoryFilter?: (categoryId: string) => void;
  onSelectAdvisorFilter?: (advisorId: string) => void;
  onSelectYearFilter?: (year: string) => void;
  projects: ProjectAPI[];
  yearFilters: { value: string; label: string }[];
  categoryFilters: { value: string; label: string }[];
  advisorFilters: { value: string; label: string }[];
  filteredProjects: ProjectAPI[];
  projectCategories: ProjectCategoryAPI[];
}

export const Projects: FC<ProjectsProps> = (props) => {
  const [totalProjectsFoundVisible, setTotalProjectsFoundVisible] = useState(false);
  const [selectedYearFilter, setSelectedYearFilter] = useState(props.yearFilters[1].value);
  const onProjectDetailClick = (id: any) => {
    window.open(RoutePaths.ProjectDetails.path.replace(':projectId', id));
  };

  const onSelectYearFilter = (year: string) => {
    setSelectedYearFilter(year);
    setTotalProjectsFoundVisible(true);
    props.onSelectYearFilter?.(year);
  };

  const onSelectCategoryFilter = (categoryId: string) => {
    setTotalProjectsFoundVisible(true);
    props.onSelectCategoryFilter?.(categoryId);
  };

  const onSelectAdvisorFilter = (advisorId: string) => {
    setTotalProjectsFoundVisible(true);
    props.onSelectAdvisorFilter?.(advisorId);
  };

  return (
    <>
      <Typography>
        <Title level={4} style={{ textAlign: 'center' }}>
          <h2>JUST ANNOUNCED (6/7/23):</h2>
          <Link to={RoutePaths.Winners.path}>View the 2022-2023 Senior Project Showcase Winners</Link>
        </Title>
        <br />
        <hr />
        <br />
        <Title level={1} style={{ textAlign: 'center' }}>
          {selectedYearFilter === 'All' ? 'All Projects' : `${parseInt(selectedYearFilter) - 1}-${selectedYearFilter} Projects`}
        </Title>
        <Title level={5} style={{ textAlign: 'center', paddingBottom: '2rem' }}>
          Total:{' '}
          {selectedYearFilter === 'All'
            ? props.projects.length
            : props.projects.filter((project) => project.year?.toString() === selectedYearFilter).length}{' '}
          Project(s)
        </Title>
      </Typography>

      <Row gutter={[16, 16]}>
        <Col xl={8}>
          <Space>
            <Typography.Text>Filter by Year:</Typography.Text>
            <Select
              onSelect={(value) => {
                onSelectYearFilter(value);
              }}
              defaultValue={props.yearFilters[1].value}
              style={{ width: 200, margin: '10px' }}
              className="custom-select"
              aria-label="Filter Projects By Year"
              options={props.yearFilters}
            />
          </Space>
        </Col>
        <Col xl={8}>
          <Space>
            <Typography.Text>Filter by Category:</Typography.Text>
            <Select
              onSelect={(value) => {
                onSelectCategoryFilter(value);
              }}
              defaultValue="All"
              style={{ width: 230, margin: '10px' }}
              className="custom-select"
              aria-label="Filter Projects By Category"
              options={props.categoryFilters}
            />
          </Space>
        </Col>
        <Col xl={8}>
          <Space>
            <Typography.Text>Filter by Advisor:</Typography.Text>
            <Select
              onSelect={(value) => {
                onSelectAdvisorFilter(value);
              }}
              defaultValue="All"
              style={{ width: 200, margin: '10px' }}
              className="custom-select"
              aria-label="Filter Projects By Advisor"
              options={props.advisorFilters}
            />
          </Space>
        </Col>
      </Row>

      {/* <span className="focus"></span> */}
      {props.filteredProjects.length === 0 ? (
        <Empty description={<Typography.Text>No projects found.</Typography.Text>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          {props.filteredProjects.length > 0 && totalProjectsFoundVisible && (
            <Typography>
              <Title level={5} style={{ textAlign: 'right' }}>
                {props.filteredProjects.length} Project(s) found
              </Title>
            </Typography>
          )}
          <List
            style={{ textAlign: 'center' }}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 4,
              xxl: 6,
            }}
            dataSource={props.filteredProjects}
            renderItem={(project) => (
              <List.Item>
                <ProjectCard onProjectDetailClick={onProjectDetailClick} project={project} projectCategories={props.projectCategories} />
              </List.Item>
            )}
          />
        </>
      )}
    </>
  );
};
