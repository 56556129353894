import { Button, Form, Input } from 'antd';
import { FC, useEffect } from 'react';
import { FileUpload } from './file-upload';

interface AddStakeholderFormProps {
  data: any; //TODO: add type
  onAddEditStakeholderFormSubmit: (values: any) => void;
}
const AddStakeholderFormFieldNames = {
  Id: 'id',
  FirstName: 'first_name',
  LastName: 'last_name',
  Email: 'email',
  Photo: 'photo',
};
export const AddStakeholderForm: FC<AddStakeholderFormProps> = (props) => {
  const [form] = Form.useForm();

  const onAddStakeholderFormSubmit = (values: any) => {
    props.onAddEditStakeholderFormSubmit(values);
  };

  useEffect(() => {
    if (!props.data) {
      form.resetFields();
      return;
    }
    const temp = {
      ...props.data,
      photo: props.data.photo
        ? [
            {
              uid: props.data.photo,
              name: props.data.photo,
              status: 'done',
              url: props.data.photo,
            },
          ]
        : undefined,
    };

    form.setFieldsValue(temp);
  }, [props.data]);

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onAddStakeholderFormSubmit}>
        <Form.Item name={AddStakeholderFormFieldNames.Id} hidden></Form.Item>
        <Form.Item
          label="First Name"
          name={AddStakeholderFormFieldNames.FirstName}
          rules={[{ required: true, message: 'Please input the name of the stakeholder' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name={AddStakeholderFormFieldNames.LastName}
          rules={[{ required: true, message: 'Please input the last name of the stakeholder' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name={AddStakeholderFormFieldNames.Email}
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input the email of the stakeholder',
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* photo */}
        <Form.Item
          label="Photo"
          name={AddStakeholderFormFieldNames.Photo}
          rules={[
            {
              required: true,
              message: 'Please upload project logo.',
            },
          ]}
        >
          <FileUpload
            errorMessage="You can only upload JPG/JPEG/PNG file."
            acceptedFileTypes="image/png,image/jpeg,image/jpg"
            listType="picture-card"
            maxCount={1}
          />
        </Form.Item>

        <Button style={{ width: '100%' }} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </>
  );
};
