import { FC } from 'react';
import { LocalStorageVariables } from '../constants';
import { CreateWebsiteFormData, ProjectCategoryAPI } from '../type-defs';
import { ProjectDetailShared } from './shared/project-detail-shared';

interface PreviewWebsiteProps {
  projectCategories: ProjectCategoryAPI[];
}
export const PreviewWebsite: FC<PreviewWebsiteProps> = (props) => {
  // credit: https://stackoverflow.com/questions/42173786/react-router-pass-data-when-navigating-programmatically
  const data = JSON.parse(localStorage.getItem(LocalStorageVariables.PreviewWebsiteData) ?? '') as CreateWebsiteFormData; // Read values passed on state

  return (
    <>
      <ProjectDetailShared
        logo={data.logo?.[0].url ?? ''}
        year={data.year ?? undefined}
        projectName={data.projectName ?? ''}
        projectCategory={data.projectCategory ?? 0}
        screenshots={data.screenshots?.map((item) => item.url).join(';') ?? ''}
        oneLiner={data.oneLiner ?? ''}
        abstract={data.abstract ?? ''}
        video={data.video ?? ''}
        selectedMembers={data.selectedMembers ?? []}
        selectedAdvisors={data.selectedAdvisors ?? []}
        selectedStakeholders={data.selectedStakeholders ?? []}
        projectCategories={props.projectCategories}
        memo={data.memo ?? ''}
        contact={data.contact ?? ''}
        poster={data.poster?.[0].url ?? ''}
        digitPoster={data.digitPoster?.[0].url ?? ''}
      />
    </>
  );
};
