
export const RoutePaths = {
  Home: {
    title: 'Home',
    key: 'Home',
    path: '',
  },
  About: {
    title: 'About',
    key: 'About',
    path: '/About',
  },
  StudentResources: {
    title: 'Student Resources',
    key: 'Student Resources',
    path: '/StudentResource',
  },
  ProjectCreation: {
    title: 'Project Creation',
    key: 'ProjectCreation',
    path: '/ProjectCreation',
  },
  PreviewWebsite: {
    title: 'Preview Website',
    key: 'PreviewWebsite',
    path: '/PreviewWebsite',
  },
  Projects: {
    title: 'Projects',
    key: 'Projects',
    path: '/Projects',
  },
  Judges: {
    title: 'Judges',
    key: 'Judges',
    path: '/Judges',
  },
  Sponsors: {
    title: 'Sponsors',
    key: 'Sponsors',
    path: '/Sponsors',
  },
  Login: {
    title: 'Login',
    key: 'Login',
    path: '/Login',
  },
  Logout: {
    title: 'Logout',
    key: 'Logout',
    path: '/',
  },
  ProjectDetails: {
    title: 'Project Details',
    key: 'ProjectDetails',
    path: '/projects/:projectId',
  },

  MemberInfo: {
    title: 'Member Info',
    key: 'MemberInfo',
    path: '/MemberInfo',
  },
  Winners: {
    title: 'Winners',
    key: 'Winners',
    path: '/Winners',
  },
  Ideas: {
    title: 'Idea Submission',
    key: 'Ideas',
    path: '/IdeaSubmission',
  },
  WebInstructions: {
    title: 'Senior Project Website Creation Instructions',
    key: 'WebInstructions',
    path: '/WebInstructions',
  },
};