import { EyeOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, Descriptions, Grid, Image, List, Row, Typography } from 'antd';
import { FC } from 'react';
import ReactPlayer from 'react-player';
import { AvatarSize, getKalturaVideoUrl } from '../../constants';
import { ProjectCategoryAPI, StudentAPI, UserAPI } from '../../type-defs';
const { useBreakpoint } = Grid;
const { Meta } = Card;
interface ProjectDetailSharedProps {
  logo?: string;
  year?: number;
  projectName?: string;
  projectCategory?: number;
  screenshots?: string;
  oneLiner?: string;
  abstract?: string;
  video?: string;
  memo?: string;
  contact?: string;
  poster?: string;
  digitPoster?: string;

  selectedMembers: StudentAPI[];
  selectedAdvisors: UserAPI[];
  selectedStakeholders: UserAPI[];

  projectCategories: ProjectCategoryAPI[];
}
export const ProjectDetailShared: FC<ProjectDetailSharedProps> = (props) => {
  const screens = useBreakpoint();

  return (
    <>
      <Row justify="center" align="top">
        <Col>
          <div style={{ textAlign: 'center' }}>
            <Image
              width={300}
              src={props.logo}
              style={{ alignSelf: 'center' }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = 'https://via.placeholder.com/300x300.png?text=Logo+Broken';
              }}
            />
          </div>
          <Descriptions layout="vertical" size="small" style={{ alignSelf: 'center', marginTop: '20px' }}>
            <Descriptions.Item label="Year">{props.year ? props.year - 1 + '-' + props.year : ''}</Descriptions.Item>
            <Descriptions.Item label="Project Name">{props.projectName}</Descriptions.Item>
            <Descriptions.Item label="Category">
              {props.projectCategories?.find((obj) => obj.id === props.projectCategory)?.projectCategory}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      {/* Screenshots */}

      <Typography.Title level={5} style={{ marginTop: '20px' }}>
        Screenshots
      </Typography.Title>
      <List
        style={{ textAlign: 'center' }}
        grid={{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 6, xl: 6, xxl: 6 }}
        dataSource={props.screenshots?.split(';') ?? []}
        renderItem={(item) => (
          <List.Item>
            <Image
              src={item}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = 'https://via.placeholder.com/300x300.png?text=Sreenshot+Broken';
              }}
            />
          </List.Item>
        )}
      />

      {/* short description */}
      <Typography.Title level={5} style={{ marginTop: '20px' }}>
        Short Description:{' '}
      </Typography.Title>
      <Typography.Paragraph>{props.oneLiner}</Typography.Paragraph>
      {/* long description */}
      <Typography.Title level={5}>Long Description:</Typography.Title>
      <Typography.Paragraph>{props.abstract}</Typography.Paragraph>

      {props.video && (
        <>
          <Typography.Title level={5}>Project Video:</Typography.Title>
          <Row justify="center">
            <Col style={{ width: '100%' }}>
              <ReactPlayer
                width={screens.xs || !screens.xl ? '100%' : '640px'}
                height={screens.xs || !screens.xl ? '100%' : '320px'}
                url={getKalturaVideoUrl(props.video)}
                controls
              />
            </Col>
          </Row>
        </>
      )}

      {/* poster */}
      {props.poster && (
        <>
          <Typography.Title level={5} style={{ marginTop: '20px' }}>
            Poster:{' '}
            <a href={props.poster} target="_blank" rel="noreferrer">
              <EyeOutlined /> View Poster
            </a>
          </Typography.Title>
        </>
      )}

      {/* digital poster */}
      {props.digitPoster && (
        <>
          <Typography.Title level={5} style={{ marginTop: '20px' }}>
            Digital Poster:{' '}
            <a href={props.digitPoster} target="_blank" rel="noreferrer">
              <EyeOutlined /> View Digital Poster
            </a>
          </Typography.Title>
        </>
      )}

      {/* Team members */}
      <Typography.Title level={3}>Team Members</Typography.Title>
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 6 }}
        dataSource={props.selectedMembers}
        renderItem={(item) => (
          <List.Item>
            <Card
              style={{ textAlignLast: 'center', width: 240 }}
              cover={
                item.user.photo ? (
                  <Image
                    width={240}
                    height={240}
                    src={item.user.photo ?? ''}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = 'https://via.placeholder.com/300x300.png?text=Photo+Broken';
                    }}
                  />
                ) : (
                  <Image preview={false} width={240} height={240} src={'https://via.placeholder.com/300x300.png?text=Photo+Here'} />
                  // <FileImageOutlined style={{ fontSize: '40px', lineHeight: '240px', borderBottom: '1px solid #f0f0f0' }} size={240} />
                )
              }
            >
              <Meta
                style={{ textAlign: 'center', wordWrap: 'break-word' }}
                title={
                  <>
                    <Typography.Text>
                      {item.user.first_name} {item.user.last_name}
                    </Typography.Text>
                    {item.is_leader ? <Typography.Text type="secondary"> (Leader)</Typography.Text> : <></>}
                  </>
                }
                description={`(${item.user.email})`}
              />
            </Card>
          </List.Item>
        )}
      />

      {/* missing member memo */}
      {props.memo && (
        <>
          <Typography.Title level={5} style={{ marginTop: '20px', color: 'red' }}>
            Missing Member Memo:
          </Typography.Title>
          <Typography.Paragraph>
            {props.memo}. Please contact us at <a href={`mailto:${props.contact}?subject=Request to join team`}>{props.contact}.</a>
          </Typography.Paragraph>
        </>
      )}

      {/* advisors */}
      <Typography.Title level={3}>Advisors</Typography.Title>
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6 }}
        dataSource={props?.selectedAdvisors}
        renderItem={(item) => (
          <List.Item>
            <Card style={{ width: 220, height: 150 }}>
              <Card.Meta
                avatar={
                  <Avatar
                    size={AvatarSize}
                    src={
                      <Image
                        src={item.user.photo}
                        preview={{ mask: <EyeOutlined /> }}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = 'https://via.placeholder.com/300x300.png?text=Photo+Broken';
                        }}
                      />
                    }
                  />
                }
                title={
                  <Typography.Text style={{ whiteSpace: 'break-spaces', fontSize: '0.75rem' }}>
                    {item.user.first_name + ' ' + item.user.last_name}
                  </Typography.Text>
                }
              />
              <br />
              <Typography.Text style={{ color: 'grey', fontSize: '0.75rem', whiteSpace: 'break-spaces' }}>{item.user.email}</Typography.Text>
            </Card>
          </List.Item>
        )}
      />

      {/* Stakeholders */}
      <Typography.Title level={3}>Stakeholders</Typography.Title>
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6 }}
        dataSource={props.selectedStakeholders}
        renderItem={(item) => (
          <List.Item>
            <Card style={{ width: 220, height: 150 }}>
              <Card.Meta
                avatar={
                  <Avatar
                    size={AvatarSize}
                    src={
                      <Image
                        src={item.user.photo}
                        preview={{ mask: <EyeOutlined /> }}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = 'https://via.placeholder.com/300x300.png?text=Photo+Broken';
                        }}
                      />
                    }
                  />
                }
                title={
                  <Typography.Text style={{ whiteSpace: 'break-spaces', fontSize: '0.75rem' }}>
                    {item.user.first_name + ' ' + item.user.last_name}
                  </Typography.Text>
                }
              />
              <br />
              <Typography.Text style={{ color: 'grey', fontSize: '0.75rem', whiteSpace: 'break-spaces' }}>{item.user.email}</Typography.Text>
            </Card>
          </List.Item>
        )}
      />
    </>
  );
};
