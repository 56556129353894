import { message, Skeleton } from 'antd';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ServerURL } from '../constants';
import { ProjectCategoryAPI, StudentAPI, TeamMemberAPI, UserAPI } from '../type-defs';
import { ProjectDetails } from './project-details';

interface ProjectDetailsContainerProps {}

export const ProjectDetailsContainer: FC<ProjectDetailsContainerProps> = (props) => {
  const [data, setData] = useState({});
  const { projectId } = useParams();
  const [loading, setLoading] = useState(false);

  const [selectedMembers, setSelectedMembers] = useState<StudentAPI[]>([]);

  const [selectedAdvisors, setSelectedAdvisors] = useState<UserAPI[]>([]);

  const [selectedStakeholders, setSelectedStakeholders] = useState<UserAPI[]>([]);

  const [projectCategories, setProjectCategories] = useState<ProjectCategoryAPI[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // get project details
        const response = await axios.get(`${ServerURL}/api/project/${projectId}/`);
        setData(response.data);

        // get student list for student dropdown
        await axios.get(`${ServerURL}/api/students/`).then(async (studentsResponse) => {
          // get selected members
          await axios.get(`${ServerURL}/api/project/members/${projectId}/`).then((teamMemberResponse) => {
            // convert TeamMemberAPI to StudentAPI
            const temp: StudentAPI[] = [];
            teamMemberResponse.data.forEach((member: TeamMemberAPI) => {
              const student = studentsResponse.data?.find((student: StudentAPI) => student.user_id === member.profileID);
              if (student) {
                temp.push(student);
              }
            });
            setSelectedMembers(temp);
          });
        });

        // get advisor list for advisor dropdown
        await axios.get(`${ServerURL}/api/users/`).then((advisorResponse) => {
          const tempAdvisors: UserAPI[] = advisorResponse.data.filter((user: UserAPI) => user.is_advisor);
          const tempStakeholders: UserAPI[] = advisorResponse.data.filter((user: UserAPI) => user.is_stakeholder);
          // get selected advisors
          axios.get(`${ServerURL}/api/project/advisors/${projectId}/`).then((response) => {
            // convert response data type to UserAPI
            const temp: UserAPI[] = [];
            response.data.forEach((item: any) => {
              const advisor = tempAdvisors.find((advisor: UserAPI) => advisor.user.id === item.profileID);
              if (advisor) {
                temp.push(advisor);
              }
            });
            setSelectedAdvisors(temp);
          });

          // get selected stakeholders
          axios.get(`${ServerURL}/api/project/stakeholders/${projectId}/`).then((response) => {
            // convert response data type to UserAPI
            const temp: UserAPI[] = [];
            response.data.forEach((item: any) => {
              const stakeholder = tempStakeholders.find((stakeholder: UserAPI) => stakeholder.user.id === item.profileID);
              if (stakeholder) {
                temp.push(stakeholder);
              }
            });
            setSelectedStakeholders(temp);
          });
        });

        // get project categories
        await axios.get(`${ServerURL}/api/projectcate/`).then((res) => {
          setProjectCategories(res.data);
        });
      } catch (error: any) {
        message.error('Something went wrong. Please try again later.');
        // HandleErrorFromBackend(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [projectId]);

  if (loading) return <Skeleton active />;

  return (
    <ProjectDetails
      projectCategories={projectCategories}
      selectedAdvisors={selectedAdvisors}
      selectedStakeholders={selectedStakeholders}
      selectedMembers={selectedMembers}
      project={data}
    />
  );
};
