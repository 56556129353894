import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Upload, UploadFile, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import { RcFile } from 'antd/lib/upload';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { HandleErrorFromBackend, ServerURL, SessionStorageVariables } from '../../constants';

type listType = 'text' | 'picture' | 'picture-card';
const ImageFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
interface ImageUploadProps {
  value?: UploadFile[];
  onChange?: (value: UploadFile[]) => void;
  listType?: listType;
  maxCount?: number;
  acceptedFileTypes?: string; //"image/png,image/jpeg"
  prompt?: string;
  errorMessage?: string;
}
export const FileUpload: FC<ImageUploadProps> = (props) => {
  const [listType, setListType] = useState('');

  useEffect(() => {
    if (props.listType) setListType(props.listType);
  }, [props.listType]);

  const customRequest = async (options: any) => {
    const formData = new FormData();
    if (ImageFileTypes.includes(options.file.type)) {
      formData.append('image', options.file);
    } else {
      formData.append('pdf', options.file);
    }

    try {
      const response = await axios.post(`${ServerURL}/api/upload/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Token ' + sessionStorage.getItem(SessionStorageVariables.AuthToken) ?? '',
        },
      });
      const fileUrl = response.data.image ?? response.data.pdf;
      // if upload 1 file only => replace the file
      // if upload multiple files => add to the list
      if (props.maxCount === 1) {
        props.onChange?.([
          {
            uid: fileUrl.substring(fileUrl.lastIndexOf('/') + 1), //get filename
            name: options.file.name,
            status: 'done',
            url: fileUrl,
          },
        ]);
      } else {
        props.onChange?.([...(props.value ?? []), { uid: fileUrl, name: options.file.name, status: 'done', url: fileUrl }]);
      }

      // options.onSuccess(options.file);
      message.success(`${options.file.name} file uploaded successfully`);
    } catch (error) {
      HandleErrorFromBackend(error);
    }
  };

  const onRemove = (file: UploadFile) => {
    const newFileList = props.value?.filter((f: any) => f.uid !== file.uid);
    props.onChange?.(newFileList ?? []);
  };

  const beforeUpload = async (file: RcFile, fileList: RcFile[]): Promise<boolean> => {
    const isValidFileType = props.acceptedFileTypes?.split(',').some((type) => file.type === type);

    if (!isValidFileType) {
      message.error(props.errorMessage ?? 'Invalid file type');
      return Promise.reject(false);
    }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //   message.error('Image must smaller than 2MB!');
    // }
    return Promise.resolve(isValidFileType || Upload.LIST_IGNORE);
  };
  return (
    <>
      {listType && listType === 'picture-card' ? (
        <ImgCrop rotate beforeCrop={beforeUpload}>
          <Upload
            fileList={props.value}
            accept={props.acceptedFileTypes}
            multiple={false}
            listType={listType ?? 'picture-card'}
            maxCount={props.maxCount ?? 1}
            // beforeUpload={beforeUpload}
            openFileDialogOnClick
            customRequest={customRequest}
            onRemove={onRemove}
          >
            {(props.value === undefined || props.value?.length < (props.maxCount ?? 1)) && (
              <div style={{ cursor: 'pointer' }}>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>{props.prompt ?? 'Upload'}</div>
              </div>
            )}
          </Upload>
        </ImgCrop>
      ) : (
        <Upload
          beforeUpload={beforeUpload}
          fileList={props.value}
          accept={props.acceptedFileTypes}
          multiple={false}
          listType={'text'}
          maxCount={props.maxCount ?? 1}
          // beforeUpload={beforeUpload}
          openFileDialogOnClick
          customRequest={customRequest}
          onRemove={onRemove}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      )}
    </>
  );
};
