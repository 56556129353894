import { FC } from 'react';
import { ProjectsContainer } from '../components/projects.container';

interface ProjectsProps {}

export const Projects: FC<ProjectsProps> = (props) => {
  return (
    <ProjectsContainer/>
  );
};
