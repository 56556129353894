import { Typography } from 'antd';
import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {  SessionStorageVariables } from '../constants';
import { useAuth } from './auth-context';
import { RoutePaths } from '../route-definitions';

interface ProtectedRouteForLeaderProps {
  children: any;
}
export const ProtectedRouteForLeader: FC<ProtectedRouteForLeaderProps> = (props) => {
  const auth = useAuth();
  const location = useLocation();


  // if not login yet, redirect to login page
  if (!auth?.token && !sessionStorage.getItem(SessionStorageVariables.AuthToken)) {
    return <Navigate to={RoutePaths.Login.path} replace state={{ from: location }} />;
  }

  // if login, is a leader, and the page is for leader, render the page
  if (auth?.isLeader || sessionStorage.getItem(SessionStorageVariables.IsLeader) === 'true') {
      return props.children;
  }
  return <Typography.Text>You don't have permission to access this page</Typography.Text>;

  // // if login, is not a leader, and the page is not for leader, render the page
  // if (!auth?.isLeader) {
  //   if (!props.forLeader) {
  //     return props.children;
  //   } else {
  //     return <Typography.Text>You don't have permission to access this page</Typography.Text>;
  //   }
  // }

  // // if (props.forLeader && auth?.isLeader) {
  // //   return props.children;
  // // } else if (!props.forLeader) { // if login, and is not leader, redirect to home page
  // //   return <Typography.Text>You don't have permission to access this page</Typography.Text>;
  // // } else if (props.forLeader && !auth?.isLeader) {

  // // }
 
};
